import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import { InputText } from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import { loadEmergencyContact, scrollPageUp } from "../../module/functions";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import WarningNotif from "../modal/WarningNotif";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentEmergencyContactForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  let yupSchema = "";

  const initVal = {
    emprimary: store.emergencyContact.emprimary,
    emptel: store.emergencyContact.emptel,
    empotherPhone: store.emergencyContact.empotherPhone,
    emsecondary: store.emergencyContact.emsecondary,
    emstel: store.emergencyContact.emstel,
    emsotherPhone: store.emergencyContact.emsotherPhone,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      emprimary: Yup.string().required("Required field"),
      // emptel: Yup.string().required("Required field"),
      empotherPhone: Yup.string().required("Required field"),
      // emsecondary: Yup.string().required("Required field"),
      // emstel: Yup.string().required("Required field"),
      // emsotherPhone: Yup.string().required("Required field"),
    });
  }

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadEmergencyContact(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                {store.toggleStudNo && <WarningNotif />}
                <p className="form-title">
                  <strong>EMERGENCY CONTACTS</strong>
                </p>
                <p>
                  An emergency contact person is someone <strong>OTHER</strong>{" "}
                  than the already listed parents or guardians.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <InputText
                    label="Primary Contact Person"
                    type="text"
                    id="idPrimaryPerson"
                    name="emprimary"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Home Tel. No."
                    type="text"
                    id="idPrimaryTel"
                    name="emptel"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Mobile Phone"
                    type="text"
                    id="idPrimaryOtherPhone"
                    name="empotherPhone"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Secondary Contact Person"
                    type="text"
                    id="idSecondaryPerson"
                    name="emsecondary"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Home Tel. No."
                    type="text"
                    id="idSecondaryTel"
                    name="emstel"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Mobile Phone"
                    type="text"
                    id="idSecondaryOtherPhone"
                    name="emsotherPhone"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>

                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentEmergencyContactForm;
