import React from "react";

const FooterEnrollment = (props) => {
  return (
    <section className="form-footer">
      <div className="container">
        <div className="">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-box t-center color--darkgray">
                <img
                  src="img/fca-new-gray-logo-2022.png"
                  alt="fca logo gray"
                  width="200px"
                />
                <h5>
                  {" "}
                  <a href="https://fca.edu.ph/" className="btn-green">
                    {" "}
                    www.fca.edu.ph{" "}
                  </a>
                </h5>
                <h5>
                  {" "}
                  Baloc Road, Brgy. San Ignacio, San Pablo City, Laguna, 4000
                </h5>
                <h5> info@fca.edu.ph</h5>
                <h5> Tel. No: 049 521 0732 </h5>
                <h5> Mobile No: 0908 202 0749 / 0926 363 9722</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterEnrollment;
