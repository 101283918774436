import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import {
  InputText,
  MyRadio,
  MyRadioError,
} from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import {
  loadParentSecond,
  scrollPageUp,
  handlepsAddressYes,
  handlepsAddressNo,
} from "../../module/functions";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import WarningNotif from "../modal/WarningNotif";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentParentSecondForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  let yupSchema = "";

  const initVal = {
    psrelation: store.parentInfoSecond.psrelation,
    pslastname: store.parentInfoSecond.pslastname,
    psfirstname: store.parentInfoSecond.psfirstname,
    psaddress: store.parentInfoSecond.psaddress,
    pscity: store.parentInfoSecond.pscity,
    psprovince: store.parentInfoSecond.psprovince,
    pspostal: store.parentInfoSecond.pspostal,
    psresideWith: store.parentInfoSecond.psresideWith,
    psphone: store.parentInfoSecond.psphone,
    psmobile: store.parentInfoSecond.psmobile,
    psdob: store.parentInfoSecond.psdob,
    psemail: store.parentInfoSecond.psemail,
    psreligion: store.parentInfoSecond.psreligion,
    psoccupation: store.parentInfoSecond.psoccupation,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      psrelation: Yup.string().required("Required field"),
      pslastname: Yup.string().required("Required field"),
      psfirstname: Yup.string().required("Required field"),
      psaddress: store.ispsSameAddress
        ? Yup.string().required("Required field")
        : null,
      pscity: store.ispsSameAddress
        ? Yup.string().required("Required field")
        : null,
      psprovince: store.ispsSameAddress
        ? Yup.string().required("Required field")
        : null,
      pspostal: store.ispsSameAddress
        ? Yup.string().required("Required field")
        : null,
      psresideWith: Yup.string().required("Required field"),
      // psphone: Yup.string().required("Required field"),
      psmobile: Yup.string().required("Required field"),
      psdob: Yup.string().required("Required field"),
      psemail: Yup.string()
        .email("Email is invalid")
        .required("Required field"),
      psreligion: Yup.string().required("Required field"),
      psoccupation: Yup.string().required("Required field"),
    });
  }

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadParentSecond(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                {store.toggleStudNo && <WarningNotif />}
                <p className="form-title">
                  <strong>PARENT OR GUARDIAN INFORMATION</strong>
                </p>
                <p>
                  <strong>SECOND PARENT/GUARDIAN</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="label">
                    Relationship to Student: (please select one)
                  </label>
                  <div className="radio-inline mb--20">
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        name="psrelation"
                        value="biological-2"
                        id="idSecondParentBiological"
                      />
                      <label htmlFor="idSecondParentBiological">
                        Biological or adoptive father
                      </label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        name="psrelation"
                        value="stepmother-2"
                        id="idSecondParentStepMother"
                      />
                      <label htmlFor="idSecondParentStepMother">
                        Step-father
                      </label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        name="psrelation"
                        value="other-2"
                        id="idSecondParentOther"
                      />
                      <label htmlFor="idSecondParentOther">Other</label>
                    </div>
                    <MyRadioError name="psrelation" />
                  </div>
                </div>

                <div className="form-group">
                  <InputText
                    label="Last Name"
                    type="text"
                    id="idSecondParentLastname"
                    name="pslastname"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="First Name"
                    type="text"
                    id="idSecondParentFirstname"
                    name="psfirstname"
                  />
                </div>

                <div className="form-group">
                  <label className="label" htmlFor="gender">
                    Does the student reside with this individual?
                  </label>
                  <div className="radio-inline mb--20">
                    <div className="radio">
                      <MyRadio
                        label="Yes"
                        type="radio"
                        id="idSecondParentResideWithYes"
                        name="psresideWith"
                        value="yes"
                        onClick={() => handlepsAddressYes(dispatchStore, props)}
                      />
                    </div>
                    <div className="radio">
                      <MyRadio
                        label="No"
                        type="radio"
                        id="idSecondParentResideWithNo"
                        name="psresideWith"
                        value="no"
                        onClick={() => handlepsAddressNo(dispatchStore)}
                      />
                    </div>
                    <MyRadioError name="psresideWith" />
                  </div>
                </div>
                {store.ispsSameAddress && (
                  <>
                    <div className="form-group">
                      <InputText
                        label="Address (if different from student's):"
                        type="text"
                        id="idSecondParentAddress"
                        name="psaddress"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="City"
                        type="text"
                        id="idSecondParentCity"
                        name="pscity"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="Province"
                        type="text"
                        id="idSecondParentProvince"
                        name="psprovince"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="Postal"
                        type="text"
                        id="idSecondParentPostal"
                        name="pspostal"
                      />
                    </div>
                  </>
                )}
                <div className="form-group">
                  <InputText
                    label="Home Phone No."
                    type="text"
                    id="idSecondParentPhone"
                    name="psphone"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Mobile Phone No."
                    type="text"
                    id="idSecondParentMobile"
                    name="psmobile"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Birth Date"
                    type="date"
                    id="idSecondParentBirthday"
                    name="psdob"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Email"
                    type="email"
                    id="idSecondParentEmail"
                    name="psemail"
                    // placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Religion"
                    type="text"
                    id="idSecondParentReligion"
                    name="psreligion"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Occupation"
                    type="text"
                    id="idSecondParentOccupation"
                    name="psoccupation"
                  />
                </div>

                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>

                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentParentSecondForm;
