import React from "react";
import { StoreContext } from "../../store/StoreContext";
import ErrorNotif from "../modal/ErrorNotif";
import SignupForm from "./SignupForm";

const Signup = (props) => {
  const { store } = React.useContext(StoreContext);

  const buttonStyle = {
    width: "100%",
    display: "block",
    borderRadius: "50px",
  };

  return (
    <>
      <div className="container main-top" id="login">
        <div className="content-block no-mg no-pd">
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-4 bg-white"
              id="login-input"
            >
              <div className="">
                <div className="logo t-center">
                  <img src="img/fca-logo.png" alt="" className="mb--10" />
                </div>
                <div
                  className="logo t-center"
                  style={{ height: "auto", marginBottom: "20px" }}
                >
                  <span>NEW USER SIGN UP PAGE</span>
                </div>

                {store.error && <ErrorNotif />}
                <SignupForm btStyle={buttonStyle} props={props} />
                {/* <a
                  href="login.html"
                  id="idBackABtn"
                  name="nameBackBtn"
                  className="btn-green form-control t-center"
                  style={buttonStyle}
                >
                  BACK
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
