import React from "react";
import { StoreContext } from "../../../store/StoreContext";
import {
  baseUrl,
  capitalFirstLetter,
  fetchData,
} from "../../../module/functions";
import { Link } from "react-router-dom";
import {
  setIsDel,
  setDelId,
  loadMessage,
  loadSpinner,
  // loadError,
  setIsDelTeacher,
  setTeacherDataEdit,
  setUserEmail,
} from "../../../store/StoreActions";

const AdminTeacherTableList = () => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const [data, setData] = React.useState([]);

  const th1 = { width: "180px" };
  const th2 = { width: "180px" };
  const th3 = { width: "180px" };
  const th4 = { width: "400px" };
  const th5 = { width: "150px" };
  const th6 = { width: "100px" };

  React.useEffect(() => {
    const getTeacherData = async () => {
      dispatchStore(loadSpinner(true));
      // do ajax
      const tData = await fetchData(
        baseUrl + "rest/admin/teacher/read-teacher-list.php",
        {},
        dispatchStore
      );

      if (typeof tData === "undefined") {
        dispatchStore(loadSpinner(false));
        return;
      }

      console.log(tData);
      setData(tData.data);
      // // When no records found
      // if (!tData.status) {
      //   console.log(1);
      //   dispatchStore(loadSpinner(false));
      //   dispatchStore(loadError(true));
      //   dispatchStore(loadMessage(tData.message));
      //   return;
      // }
      dispatchStore(loadSpinner(false));
    };
    getTeacherData();
  }, [dispatchStore, store.info]);

  const clickDelete = (item) => {
    dispatchStore(setIsDel(true));
    dispatchStore(setIsDelTeacher(true));
    dispatchStore(setDelId(item.teacher_id));
    dispatchStore(setUserEmail(item.teacher_email));
    dispatchStore(
      loadMessage("Are you sure you want to delete this teacher record?")
    );
  };

  return (
    <>
      <table
        id="student-table"
        className=""
        cellSpacing="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th1}>
              Employee Code
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th2}>
              Last Name
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th3}>
              First Name
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th4}>
              Email
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th5}>
              Class Handled
            </th>
            <th className="action" rowSpan="1" colSpan="1" style={th6}>
              Action
            </th>
          </tr>
        </thead>
        <tbody id="idTableBodyStudent">
          {/* {store.studentData.length && */}
          {data.map((item) => (
            <tr key={item.teacher_id}>
              <td
                className="learner_no"
                tabIndex="0"
                rowSpan="1"
                colSpan="1"
                style={th1}
              >
                {item.teacher_code}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th2}>
                {capitalFirstLetter(item.teacher_lname)}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th3}>
                {capitalFirstLetter(item.teacher_fname)}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th4}>
                {item.teacher_email}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th5}>
                {/* {item.teacher_email} */}
              </td>
              <td className="action" rowSpan="1" colSpan="1" style={th6}>
                {/* <Link id="idAview" to="/admin/student/view" className="view">
                  <i className="fas fa-eye"></i>
                </Link> */}
                <Link
                  id="idAedit"
                  to="/admin/teacher/edit"
                  className="edit"
                  onClick={() => dispatchStore(setTeacherDataEdit(item))}
                >
                  <i className="fas fa-pencil-alt"></i>
                </Link>
                <Link
                  id="idAdelete"
                  to="/admin/teacher"
                  className="delete"
                  onClick={() => clickDelete(item)}
                >
                  <i className="fas fa-trash-alt"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="t-center">{data.length === 0 && "No Data"}</p>
    </>
  );
};

export default AdminTeacherTableList;
