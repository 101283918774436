import React from "react";
// import { StoreContext } from "../../store/StoreContext";

const Spinner = () => {
  // const { store } = React.useContext(StoreContext);
  return (
    <>
      <div className="spinner">
        <div className="spin center-vertically">
          {/* <span>Loading...</span> */}
        </div>
      </div>
    </>
  );
};

export default Spinner;
