import React from "react";
import HeaderEnrollment from "./HeaderEnrollment";
import FooterEnrollment from "./FooterEnrollment";
import PageNumber from "./PageNumber";
import { setPageNum } from "../../store/StoreActions";
import { StoreContext } from "../../store/StoreContext";

const EnrollmentIntro = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const handleClick = (e) => {
    dispatchStore(setPageNum(store.pageNum + 1));
  };

  return (
    <>
      <section className="form-container bg-gray">
        <div className="container">
          <HeaderEnrollment />

          <div className="bg-white">
            <div id="id-form-student" className="form-content">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="content">
                    <p>
                      Frontline Christian Academy, Inc. <br />
                      Flora Drive, Brgy. San Ignacio, <br />
                      San Pablo City, Laguna, Phil. 4000 <br />
                      (049) 521-0732 / 0926-363-9722 / 0908-202-0749
                    </p>
                    <p>
                      This registration form is an official document. It must be
                      accurate and complete.
                    </p>
                    <p>
                      Before a student can be enrolled in this school, this
                      registration form must be completed in its entirety and
                      digitally signed by the parent or guardian.
                    </p>
                    <p>
                      The student registration form is used to enroll a student
                      who is new to Frontline Christian Academy, Inc., or who is
                      returning to the school after one (1) or more years of not
                      being enrolled in FCA.
                    </p>
                    <p>
                      All information entered in this form is for school use
                      only and is strictly confidential. No part of this
                      document may be disclosed in any manner to a third party
                      without the prior written consent of the person filling
                      this out.
                    </p>
                  </div>
                  <div className="content t-center clearfix mb--20">
                    <button
                      onClick={handleClick}
                      className="btn-green form-control right btn-next"
                      id="view-parent"
                      name="btnNameNextOne"
                    >
                      NEXT <i className="fas fa-angle-right"> </i>{" "}
                      {/* <h4> Student Information </h4> */}
                    </button>
                  </div>

                  <div className="content t-center">
                    <PageNumber />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterEnrollment />
    </>
  );
};

export default EnrollmentIntro;
