import React from "react";
import HeaderEnrollment from "./HeaderEnrollment";
import FooterEnrollment from "./FooterEnrollment";
import EnrollmentDocsForm from "./EnrollmentDocsForm";
import ModalError from "../modal/ModalError";
import { StoreContext } from "../../store/StoreContext";

const EnrollmentDocs = ({ props }) => {
  const { store } = React.useContext(StoreContext);
  return (
    <>
      <section className="form-container bg-gray main-top">
        <div className="container">
          <HeaderEnrollment />
          <div className="bg-white">
            {store.error && <ModalError />}
            <EnrollmentDocsForm props={props} />
          </div>
        </div>
      </section>
      <FooterEnrollment />
    </>
  );
};

export default EnrollmentDocs;
