import React from "react";

const NewPasswordSuccess = () => {
  const storage = localStorage.getItem("sv");

  React.useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
    }, 1000);
  }, []);

  const imgStyle = { margin: "10px" };

  return (
    <>
      {storage ? (
        <div className="container" id="login">
          <div className="signup-content">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-3"></div>
              <div className="col-lg-6 col-md-4 col-sm-6 bg-white signup-box">
                <form
                  id="idFormSignUp"
                  name="nameFormSignUp"
                  className="t-center"
                >
                  <img src="img/fca-logo.png" alt="" className="mb--20" />
                  <h1 className="t-bold mb--30">All Set!</h1>
                  <p>
                    Your new password has been successfully set! You can now log
                    in using your new password.
                  </p>
                  <a href="/login" className="btn-square mb--30">
                    PROCEED TO LOGIN
                  </a>

                  <hr style={{ margin: "20px 0" }} />
                  <ul>
                    <li>
                      <a href="https://fca.edu.ph/">
                        <img
                          style={imgStyle}
                          src="https://ems.frontlinebusiness.com.ph/rest/account/mail/images/globe-asia.png"
                          alt="globe icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/frontlinechristianacademy/">
                        <img
                          style={imgStyle}
                          src="https://ems.frontlinebusiness.com.ph/rest/account/mail/images/facebook.png"
                          alt="facebook icon"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="mailto:patrick.reyes@frontlinebusiness.com.ph?Subject=Verify%20Email">
                        <img
                          style={imgStyle}
                          src="https://ems.frontlinebusiness.com.ph/rest/account/mail/images/envelope.png"
                          alt="envelope icon"
                        />
                      </a>
                    </li>
                  </ul>
                  <div className="footer">
                    <h5 className="title">Frontline Christian Academy</h5>
                    <p>
                      &copy; {new Date().getFullYear()} FCA, ALL RIGHT RESERVED
                    </p>
                    <p>202 Maharlika Highway San Francisco Calihan</p>
                    <p>San Pablo City, Laguna 4000</p>
                  </div>
                </form>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-3"></div>
            </div>
          </div>
        </div>
      ) : (
        <p>The page you're trying to view has expired or invalid.</p>
      )}
    </>
  );
};

export default NewPasswordSuccess;
