import React from "react";
import HeaderEnrollment from "./HeaderEnrollment";
import FooterEnrollment from "./FooterEnrollment";
import EnrollmentStudentInfoForm from "./EnrollmentStudentInfoForm";

const EnrollmentStudentInfo = (props) => {
  return (
    <>
      <section className="form-container bg-gray main-top">
        <div className="container">
          <HeaderEnrollment />
          <div className="bg-white">
            <EnrollmentStudentInfoForm />
          </div>
        </div>
      </section>
      <FooterEnrollment />
    </>
  );
};

export default EnrollmentStudentInfo;
