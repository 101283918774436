import React from "react";
import { StoreContext } from "../../store/StoreContext";
import {
  setIsDel,
  setLogout,
  loadSpinner,
  loadMessage,
  loadError,
  setIsDelTeacher,
  setIsDelStudent,
  loadInfo,
} from "../../store/StoreActions";
import {
  // fetchData,
  postDataStudent,
  fetchData,
  baseUrl,
  // getStudentGrade,
  // getStudentSy,
} from "../../module/functions";

const ModalBox = () => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  // const {del, setDel} = del;
  // const { serverError } = msg;

  // React.useEffect(() => {}, [store.error]);

  const clkYes = async () => {
    // delete student
    if (store.isDelStudent) {
      console.log("del student");
      dispatchStore(setIsDel(false));
      dispatchStore(setIsDelStudent(false));
      postDataStudent(
        baseUrl + "rest/admin/student/delete-student.php",
        { student_id: store.delId },
        dispatchStore
      );
      return;
    }

    // delete teacher
    if (store.isDelTeacher) {
      console.log("del teacher");
      dispatchStore(setIsDel(false));
      dispatchStore(setIsDelTeacher(false));
      dispatchStore(loadSpinner(true));
      dispatchStore(loadInfo(false));
      // do ajax
      const data = await fetchData(
        baseUrl + "rest/admin/teacher/delete-teacher.php",
        { teacher_id: store.delId, teacher_email: store.userEmail },
        dispatchStore
      );

      if (typeof data === "undefined") {
        dispatchStore(loadSpinner(false));
        return;
      }

      console.log(data.data);
      dispatchStore(loadInfo(true));

      // When no records found
      if (!data.status) {
        console.log(1);
        dispatchStore(loadSpinner(false));
        dispatchStore(loadError(true));
        dispatchStore(loadMessage(data.message));
        return;
      }
      dispatchStore(loadSpinner(false));

      return;
    }

    // logout user
    if (store.logout) {
      console.log("logout");
      localStorage.removeItem("token");
      window.location.href = "/login";
      return;
    }
  };

  const clkNo = () => {
    dispatchStore(setIsDel(false));
    dispatchStore(setLogout(false));
  };

  return (
    <div className="row no-mg" id="messagebox">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="message">
          <div className="confirm-delete">
            <i className="fas fa-check-circle mb--10"></i>
            <p>{store.message}</p>
            {/* <p>Are you sure you want to delete this record? </p> */}
            <button
              className="btn-green form-control"
              id="idBtnYes"
              style={{ marginRight: "10px" }}
              onClick={clkYes}
            >
              YES
            </button>
            <button
              className="btn-green form-control"
              id="idBtnNo"
              onClick={clkNo}
            >
              NO
            </button>
          </div>
          {/* <div class="confirm-update hide">
						<i class="fas fa-check-circle mb--10"></i>
						<p>Record has been successfully updated. </p>
						<button class="btn-green form-control" id="idBtnConfirmUpdate">DONE</button>
					</div>
					<div class="confirm-add hide">
						<i class="fas fa-check-circle mb--10"></i>
						<p>Record has been successfully saved. </p>
						<button class="btn-green form-control" id="idBtnConfirmAdd">DONE</button>
					</div> */}
        </div>
      </div>
    </div>
  );
};

export default ModalBox;
