import React, { useReducer, createContext } from "react";
import { StoreReducer } from "./StoreReducer";

const initVal = {
  loading: false,
  error: false,
  info: false,
  success: false,
  message: "",
  isDel: false,
  isDelStudent: false,
  isDelTeacher: false,
  delId: "",
  logout: false,
  serverError: false,
  serverErrorMsg: "",
  userEmail: "",
  pageNum: 1,
  toggleStudNo: false,
  ispfSameAddress: false,
  ispsSameAddress: false,
  fee: "",
  gradeIndex: "",
  otherScheme: "",
  studentData: [],
  studentDataEdit: [],
  teacherDataEdit: [],
  studentInfo: {
    sclassification: "",
    ssy: "",
    slrn: "",
    sstudentNo: "",
    sgrade: "",
    sgender: "",
    sfirstname: "",
    smiddlename: "",
    slastname: "",
    sdob: "",
    spob: "",
    semail: "",
    sphone: "",
    sphoto: "",
  },
  residence: { rcurrentAddress: "", rcity: "", rprovince: "", rpostal: "" },
  schoolHistory: {
    shschool: "",
    shschoolAddress: "",
    shgpa: "",
    shdisciplinary: "",
  },
  medicalInfo: { mnotes: "", mdoctor: "", mcontactNo: "", mcircumstance: "" },
  parentInfoFirst: {
    pfrelation: "",
    pflastname: "",
    pffirstname: "",
    pfaddress: "",
    pfcity: "",
    pfprovince: "",
    pfpostal: "",
    pfresideWith: "",
    pfphone: "",
    pfmobile: "",
    pfdob: "",
    pfemail: "",
    pfreligion: "",
    pfoccupation: "",
  },
  parentInfoSecond: {
    psrelation: "",
    pslastname: "",
    psfirstname: "",
    psaddress: "",
    pscity: "",
    psprovince: "",
    pspostal: "",
    psresideWith: "",
    psphone: "",
    psmobile: "",
    psdob: "",
    psemail: "",
    psreligion: "",
    psoccupation: "",
  },
  emergencyContact: {
    emprimary: "",
    emptel: "",
    empotherPhone: "",
    emsecondary: "",
    emstel: "",
    emsotherPhone: "",
  },
  financialInfo: {
    fifatherIncome: "",
    fiMotherIncome: "",
    fieduc: "",
    // fischeme: "",
    // fiother: "",
  },
  student_name: "",
  decname: "",
  consent_parent_name: "",
  consent_student_name: "",
  consent_decname: "",
};

const StoreContext = createContext();

const StoreProvider = (props) => {
  const [store, dispatchStore] = useReducer(StoreReducer, initVal);
  return (
    <StoreContext.Provider value={{ store, dispatchStore }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
