import React from "react";
// import { StoreContext } from "../../store/StoreContext";
// import { loadSuccess, loadInfo } from "../../store/StoreActions";

const RequiredNotif = ({ msg }) => {
  // const { serverError } = msg;
  // const { dispatchStore } = React.useContext(StoreContext);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     dispatchStore(loadInfo(false));
  //   }, 3000);
  // }, [dispatchStore]);

  return (
    <div className="notify-bar notify-show notify-error">
      <i className="fas fa-info-circle notify-element"></i>
      <div className="notify-bar-text notify-element">
        <p className="no-mg">Please provide all the required field(s).</p>
      </div>
      {/* <i
        className="fas fa-times close  notify-element"
        onClick={() => dispatchStore(loadSuccess(false))}
      ></i> */}
    </div>
  );
};

export default RequiredNotif;
