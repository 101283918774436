import React from "react";
import HeaderAdmin from "../../HeaderAdmin";
import SideNavAdmin from "../../SideNavAdmin";
import AdminStudentTableList from "./AdminStudentTableList";
import { StoreContext } from "../../../store/StoreContext";
import Spinner from "../../modal/Spinner";
import AdminStudentGradeFilter from "./AdminStudentGradeFilter";
import {
  getStudentSy,
  getStudentGrade,
  postDataStudent,
  getTodayYear,
  baseUrl,
} from "../../../module/functions";
import ModalError from "../../modal/ModalError";
import { Link } from "react-router-dom";
// import SuccessNotif from "../../modal/SuccessNotif";

const AdminStudent = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const year = getTodayYear();

  const handleChange = (e) => {
    console.log(e.target.value);
    postDataStudent(
      baseUrl + "rest/admin/student/read-student-per-grade.php",
      { student_grade: getStudentGrade(), student_sy: getStudentSy() },
      dispatchStore
    );
  };

  return (
    <>
      <HeaderAdmin />

      <SideNavAdmin menu="student" />

      {store.loading && <Spinner />}

      {store.error && <ModalError />}
      <div className="main-content">
        <div className="student-block block">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">STUDENTS </span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <Link
                  className="btn-green form-control right"
                  id="create-new"
                  to="/admin/student/add"
                >
                  <i className="fas fa-plus-circle"></i> Create New
                </Link>
              </div>
            </div>
          </div>
          {/* {store.success && <SuccessNotif msg="deleted" />} */}
          <div className="student content-block clearfix" id="student-list">
            {/* <form> */}
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <AdminStudentGradeFilter change={handleChange} />
              </div>
              <div className="col-lg-4 col-md-2 col-sm-12">
                <div className="student-filter mb--20">
                  <label className="">School Year</label>
                  <select
                    name="student_sy"
                    id="idStudent_sy"
                    className="droparrow form-control"
                    onChange={handleChange}
                  >
                    {year.map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <label className="">Type Search Keyword</label>
                <div className="search mb--20">
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="What are you looking for?"
                  />
                  <button type="submit" className="searchButton">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="border-bottom mb--20"></div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 table-col">
                <AdminStudentTableList />
              </div>
            </div>

            {/* <div id="idRowPagi" class="row">
						<div id="pagi">
							<a href="#" id="btn_prev" class="btn-green form-control"><i class="fa fa-caret-left"></i></a>
							<span id="idPageNumber">1 of 2</span>
							<a href="#" id="btn_next" class="btn-green form-control"><i class="fa fa-caret-right"></i></a>
						</div>							
					</div>			 */}
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminStudent;
