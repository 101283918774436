import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
// import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import { InputText, InputTextConsent } from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
// import { loadDocs } from "../../module/functions";
import { loadError, loadMessage } from "../../store/StoreActions";
import { postData } from "../../ajax/RequestMethod";
import { baseUrl, setStorage } from "../../module/functions";
import RequiredNotif from "../modal/RequiredNotif";
// import { setPageNum } from "../../store/StoreActions";

const EnrollmentDocsConsentForm = ({ props }) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const initVal = {
    consent_parent_name: store.consent_parent_name,
    consent_student_name: store.consent_student_name,
    consent_decname: store.consent_decname,
  };

  const yupSchema = Yup.object({
    consent_parent_name: Yup.string().required("Required field"),
    consent_student_name: Yup.string().required("Required field"),
    consent_decname: Yup.string().required("Required field"),
  });

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        // reset store context
        dispatchStore(loadError(false));

        // form data to insert
        const data = {
          ...store.studentInfo,
          ...store.residence,
          ...store.schoolHistory,
          ...store.medicalInfo,
          ...store.parentInfoFirst,
          ...store.parentInfoSecond,
          ...store.emergencyContact,
          ...store.financialInfo,
          student_name: store.student_name,
          decname: store.decname,
          ...values,
        };
        console.log(data);

        // do ajax
        const enroll = await postData(
          baseUrl + "rest/admin/student/create-student.php",
          data,
          dispatchStore
        );
        console.log(enroll);

        // API Error ocurred
        if (!enroll.data.status) {
          dispatchStore(loadError(true));
          dispatchStore(loadMessage(enroll.data.message));
          return;
        }

        // reset form
        resetForm();

        // redirect to signup verify page
        setStorage();

        window.location.replace("/thank-you");
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group pb--20">
                  <label className="label t-center mb--20">
                    PARENT CONSENT
                  </label>
                  <p className="text--justify">
                    I,{" "}
                    <InputTextConsent
                      type="text"
                      name="consent_parent_name"
                      placeholder="Parent name"
                    />{" "}
                    , as the parent or legal guardian of{" "}
                    <InputTextConsent
                      type="text"
                      name="consent_student_name"
                      placeholder="Student name"
                    />{" "}
                    hereby acknowledge that I have been informed of the details
                    of the conduct of Implementation of Limited Face-to-Face
                    learning modality. I understand that FRONTLINE CHRISTIAN
                    ACADEMY INC shall implement the minimum public health
                    standards set by the government to minimize risk of the
                    spread of COVID 19, but it cannot guarantee that my child
                    will not become ill. I acknowledge that my child/ren’s in
                    persons attendance in school will include associating with
                    teachers, fellow learners and school personnel, and other
                    persons inside and outside that may put my child at risk of
                    COVID 19 transmission, notwithstanding the precautions
                    undertaken by the school. I acknowledge that my child/ren’s
                    participation in this activity is completely voluntary.
                    While there remains the risk of possible COVID 19
                    transmissions to my child/dren, and to the members of my
                    household, I freely assume the said risk and I permit my
                    child/dren to attend the school under this activity, the
                    Face-to-Face Summer Classes. I am aware that the symptoms of
                    COVID 19 include but are not limited to, fever or chills,
                    cough, shortness of breath or difficulty of breathing, body
                    and headache, loss of taste and smell, sore throat,
                    congestion, nausea, vomiting and diarrhea. I confirm that my
                    child/dren currently has none of those symptoms, and is in
                    good health. I will not allow my child/dren to physically go
                    to school to attend classes if my child/dren or any members
                    of my household develops any said symptoms or any other
                    illness that may or may not be related to COVID 19. I will
                    also inform the school and not allow my child/dren to attend
                    Face-to-Face classes if my child/dren or any of my household
                    members tests positive for COVID 19. My child/dren and I,
                    with my household members, will follow the required health
                    and safety protocols and procedures adopted by the school
                    and our community. To the extent allowed by law and rules, I
                    hereby agree to waive, release and discharge any and all
                    claims, causes of action, damages and rights against the
                    school and its personnel as well as officials and personnel
                    of the Department of Education relative to the conduct of
                    the activity.
                  </p>
                </div>

                <div className="docuSeparator"></div>

                <div className="form-group text--justify py--40">
                  <label className="label t-center mb--20">
                    PARENT COMMITMENT FORM
                  </label>
                  <p>
                    As a partner of the school in the education of my child, I
                    commit myself to the following matters:
                  </p>
                  <ol
                    className="list-text"
                    style={{ listStyleType: "upper-roman" }}
                  >
                    <li className="mb--10">
                      Academic Matters
                      <ol style={{ marginLeft: "50px" }}>
                        <li>
                          I will read, understand and abide by the rules and
                          regulations stipulated in the FCA Student Handbook.
                        </li>
                        <li>
                          I will be actively involved in educating my children.
                        </li>
                        <li>
                          I will provide a conducive learning environment for my
                          child and do my best to keep the learning space free
                          from distractions and disturbances.
                        </li>
                      </ol>
                    </li>
                    <li className="mb--10">
                      Communication
                      <ol style={{ marginLeft: "50px" }}>
                        <li>
                          I will keep an open and honest path of communication
                          with the school and my child’s teachers regarding the
                          education of my child.
                        </li>
                        <li>
                          I will regularly check my registered email address, as
                          well as acknowledge all forms of communication sent to
                          me via cell phone or printed letters. I also commit to
                          replying to communications that require my response.
                        </li>
                        <li>
                          I commit to attending the monthly Parenting Peer
                          Groups facilitated by FCA, either in-person or
                          virtually.
                        </li>
                        <li>
                          I commit to attending the Parent-Teacher Conferences
                          either in-person or virtually.
                        </li>
                      </ol>
                    </li>
                    <li className="mb--10">
                      Disclosure
                      <ol style={{ marginLeft: "50px" }}>
                        <li>
                          I have given FCA full disclosure of my child’s former
                          and current school liabilities, as well as each
                          child’s learning and development needs, physical and
                          learning disability/ies (if any) which FCA may use as
                          a basis for recommending supplementary services, if
                          needed.
                        </li>
                      </ol>
                    </li>
                    <li className="mb--10">
                      Picture Authorization
                      <ol style={{ marginLeft: "50px" }}>
                        <li>
                          FCA may use my child/ren’s photos captured in its
                          various events, as well as their school activities and
                          produced work, for marketing purposes upon my
                          approval. I recognize that the pictures/images may be
                          used beyond the time that my child is a student of
                          FCA.
                        </li>
                      </ol>
                    </li>
                    <li className="mb--10">
                      Data Privacy
                      <ol style={{ marginLeft: "50px" }}>
                        <li>
                          The protection of your information and privacy is
                          essential to us as an educational provider. We comply
                          with all applicable data protection laws under the
                          DATA PRIVACY ACT OF 2012. You authorize and consent to
                          the use and transfer of said information for valid and
                          legitimate purposes.
                        </li>
                        <li>
                          I understand that all shared passwords and access to
                          resources, links, emails, files and other resources,
                          both paid and unpaid, will be for my child/ren and
                          will not be shared with others.
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <div className="form-group pt--20 mb--40">
                    <label className="label t-center" htmlFor="firstname">
                      Parent/Legal Guardian's Name
                      <span className="required">*</span>
                    </label>
                    <InputText type="text" name="consent_decname" />
                  </div>
                </div>

                <div className="form-groupt-center clearfix">
                  {!props.isSubmitting && <ButtonBack props={props} />}
                  <button
                    type="submit"
                    className="btn-green form-control right btn-next"
                    id="view-parent"
                    disabled={props.isSubmitting}
                  >
                    SUBMIT{" "}
                    {props.isSubmitting && (
                      <i className="fas fa-spinner fa-spin fa-1x"></i>
                    )}
                    {/* <h4> Student Information </h4> */}
                  </button>
                </div>
                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentDocsConsentForm;
