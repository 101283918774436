import React from "react";
import { StoreContext } from "../../store/StoreContext";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import {
  loadResidence,
  loadSchoolHistory,
  loadStudentInfo,
  loadMedicalInfo,
  loadParentFirst,
  loadParentSecond,
  loadEmergencyContact,
  loadFinancial,
  loadDocs,
  scrollPageUp,
  loadConsent,
} from "../../module/functions";

const ButtonBack = ({ props }) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const { values } = props;
  const handleClick = (e) => {
    // console.log(store.pageNum);
    dispatchStore(loadInfo(false));
    scrollPageUp();
    dispatchStore(setPageNum(store.pageNum - 1));
    switch (store.pageNum) {
      case 1:
        break;
      case 2:
        loadStudentInfo(dispatchStore, values);
        break;
      case 3:
        loadResidence(dispatchStore, values);
        break;
      case 4:
        loadSchoolHistory(dispatchStore, values);
        break;
      case 5:
        loadMedicalInfo(dispatchStore, values);
        break;
      case 6:
        loadParentFirst(dispatchStore, values);
        break;
      case 7:
        loadParentSecond(dispatchStore, values);
        break;
      case 8:
        loadEmergencyContact(dispatchStore, values);
        break;
      case 9:
        loadFinancial(dispatchStore, values);
        break;
      case 10:
        loadDocs(dispatchStore, values);
        break;
      case 11:
        loadConsent(dispatchStore, values);
        break;
      default:
        return <p>Page not found.</p>;
    }
  };

  return (
    <div
      onClick={handleClick}
      className="btn-green form-control left btn-next"
      id="view-parent"
      name="btnNameNextOne"
    >
      {/* BACK <i className="fas fa-angle-left"> </i> <h4> Student Information </h4> */}
      <i className="fas fa-angle-left"> </i> BACK
    </div>
  );
};

export default ButtonBack;
