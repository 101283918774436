import React from "react";
import { StoreContext } from "../../../store/StoreContext";
import {
  baseUrl,
  capitalFirstLetter,
  getStudentGrade,
  getStudentSy,
  postDataStudent,
} from "../../../module/functions";
import { Link } from "react-router-dom";
import {
  setStudentDataEdit,
  setIsDel,
  setDelId,
  loadMessage,
  setIsDelStudent,
} from "../../../store/StoreActions";

const AdminStudentTableList = () => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const th1 = { width: "250px" };
  const th2 = { width: "300px" };
  const th3 = { width: "300px" };
  const th4 = { width: "300px" };
  const th5 = { width: "150px" };
  const th6 = { width: "200px" };

  React.useEffect(() => {
    postDataStudent(
      baseUrl + "rest/admin/student/read-student-per-grade.php",
      { student_grade: getStudentGrade(), student_sy: getStudentSy() },
      dispatchStore
    );
  }, [dispatchStore, store.info]);

  const clickDelete = (item) => {
    dispatchStore(setIsDel(true));
    dispatchStore(setIsDelStudent(true));
    dispatchStore(setDelId(item.student_id));
    dispatchStore(loadMessage("Are you sure you want to delete this record?"));
  };

  return (
    <>
      <table
        id="student-table"
        className=""
        cellSpacing="0"
        width="100%"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th1}>
              Learner Ref. No.
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th2}>
              Last Name
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th3}>
              First Name
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th4}>
              Middle Name
            </th>
            <th className="" tabIndex="0" rowSpan="1" colSpan="1" style={th5}>
              Grade
            </th>
            <th className="action" rowSpan="1" colSpan="1" style={th6}>
              Action
            </th>
          </tr>
        </thead>
        <tbody id="idTableBodyStudent">
          {/* {store.studentData.length && */}
          {store.studentData.map((item) => (
            <tr
              key={item.student_id}
              className={
                item.student_enroll_status === "Temporary" ? "temporary" : null
              }
            >
              <td
                className="learner_no"
                tabIndex="0"
                rowSpan="1"
                colSpan="1"
                style={th1}
              >
                {item.student_lrn}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th2}>
                {capitalFirstLetter(item.student_ln)}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th3}>
                {capitalFirstLetter(item.student_fn)}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th4}>
                {capitalFirstLetter(item.student_mn)}
              </td>
              <td className="" tabIndex="0" rowSpan="1" colSpan="1" style={th5}>
                {capitalFirstLetter(item.student_grade)}
              </td>
              <td className="action" rowSpan="1" colSpan="1" style={th6}>
                {/* <Link id="idAview" to="/admin/student/view" className="view">
                  <i className="fas fa-eye"></i>
                </Link> */}
                <Link
                  id="idAedit"
                  to="/admin/student/edit"
                  className="edit"
                  onClick={() => dispatchStore(setStudentDataEdit(item))}
                >
                  <i className="fas fa-pencil-alt"></i>
                </Link>
                <Link
                  id="idAdelete"
                  to="/admin/student"
                  className="delete"
                  onClick={() => clickDelete(item)}
                >
                  <i className="fas fa-trash-alt"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="t-center">{store.studentData.length === 0 && "No Data"}</p>
      <span className="my--10 d-inline-block">
        <span className="t-bold">LEGEND: </span>
        <span className="temporary px--10">Temporarily Enrolled</span>
      </span>
    </>
  );
};

export default AdminStudentTableList;
