import React from "react";
import EnrollmentIntro from "./EnrollmentIntro";
import { StoreContext } from "../../store/StoreContext";
import EnrollmentResidence from "./EnrollmentResidence";
// import EnrollmentPageTest from "./EnrollmentPageTest";
import EnrollmentSchoolHistory from "./EnrollmentSchoolHistory";
import EnrollmentStudentInfo from "./EnrollmentStudentInfo";
import EnrollmentMedical from "./EnrollmentMedical";
import EnrollmentParentFirst from "./EnrollmentParentFirst";
import EnrollmentParentSecond from "./EnrollmentParentSecond";
import EnrollmentEmergencyContact from "./EnrollmentEmergencyContact";
import EnrollmentFinancial from "./EnrollmentFinancial";
import EnrollmentDocs from "./EnrollmentDocs";
import EnrollmentDocsConsent from "./EnrollmentDocsConsent";

const Enrollment = (props) => {
  const { store } = React.useContext(StoreContext);
  // console.log(props);
  // window.onbeforeunload = () => {
  //   return "Prompt user before reloading or leaving the page.";
  // };

  switch (store.pageNum) {
    case 1:
      return <EnrollmentIntro />;
    case 2:
      return <EnrollmentStudentInfo />;
    case 3:
      return <EnrollmentResidence />;
    case 4:
      return <EnrollmentSchoolHistory />;
    case 5:
      return <EnrollmentMedical />;
    case 6:
      return <EnrollmentParentFirst />;
    case 7:
      return <EnrollmentParentSecond />;
    case 8:
      return <EnrollmentEmergencyContact />;
    case 9:
      return <EnrollmentFinancial />;
    case 10:
      return <EnrollmentDocs props={props} />;
    case 11:
      return <EnrollmentDocsConsent props={props} />;
    default:
      return <p>Page not found.</p>;
  }
};

export default Enrollment;
