import React from "react";
import { StoreContext } from "../../store/StoreContext";
import { loadSuccess } from "../../store/StoreActions";

const SuccessNotif = ({ msg }) => {
  // const { serverError } = msg;
  const { dispatchStore } = React.useContext(StoreContext);

  React.useEffect(() => {
    setTimeout(() => {
      dispatchStore(loadSuccess(false));
    }, 3000);
  }, [dispatchStore]);

  return (
    <div className="notify-bar notify-show notify-success">
      <i className="fas fa-info-circle notify-element"></i>
      <div className="notify-bar-text notify-element">
        <p className="no-mg">Successfully {msg}.</p>
      </div>
      <i
        className="fas fa-times close  notify-element"
        onClick={() => dispatchStore(loadSuccess(false))}
      ></i>
    </div>
  );
};

export default SuccessNotif;
