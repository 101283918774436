import React from "react";
import { StoreContext } from "../store/StoreContext";
import { setLogout, loadMessage } from "../store/StoreActions";
import ModalBox from "./modal/ModalBox";

const HeaderAdmin = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  return (
    <>
      <header>
        <div className="logo">
          <i className="fa fa-tachometer"></i>
          <span>FCA SMIS</span>
        </div>
        <div className="user">
          <ul>
            <li>
              <div className="user-dp">
                <img
                  src="https://ems.frontlinebusiness.com.ph/rest/uploads/images/5e2a2d314af277.33874211.jpg"
                  alt="jhonny"
                />
              </div>
            </li>
            <li>
              <i
                id="idSignOut"
                className="fas fa-sign-out-alt"
                data-text="Sign Out"
                onClick={() => {
                  dispatchStore(setLogout(true));
                  dispatchStore(
                    loadMessage("Are you sure you want to logout?")
                  );
                }}
              ></i>
            </li>
            <li>
              <i className="fas fa-bars nav-trigger"></i>
            </li>
          </ul>
        </div>
      </header>
      {(store.isDel || store.logout) && <ModalBox />}
    </>
  );
};

export default HeaderAdmin;
