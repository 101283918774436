import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import {
  InputText,
  MySelect,
  MyRadio,
  MyRadioError,
} from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import {
  setPageNum,
  setToggleStudNo,
  loadInfo,
} from "../../store/StoreActions";
import { loadStudentInfo, scrollPageUp } from "../../module/functions";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentStudentInfoForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const initVal = {
    sclassification: store.studentInfo.sclassification,
    ssy: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
    slrn: store.studentInfo.slrn,
    sstudentNo: store.studentInfo.sstudentNo,
    sgrade: store.studentInfo.sgrade,
    sgender: store.studentInfo.sgender,
    sfirstname: store.studentInfo.sfirstname,
    smiddlename: store.studentInfo.smiddlename,
    slastname: store.studentInfo.slastname,
    sdob: store.studentInfo.sdob,
    spob: store.studentInfo.spob,
    semail: store.studentInfo.semail,
    sphone: store.studentInfo.sphone,
    sphoto: store.studentInfo.sphoto,
  };

  const yupSchema = Yup.object({
    sclassification: Yup.string().required("Required field"),
    // sstudentNo: store.toggleStudNo
    //   ? Yup.string().required("Required field")
    //   : null,
    sgrade: Yup.string().required("Required field"),
    sgender: !store.toggleStudNo
      ? Yup.string().required("Required field")
      : null,
    sfirstname: Yup.string().required("Required field"),
    slastname: Yup.string().required("Required field"),
    sdob: !store.toggleStudNo ? Yup.string().required("Required field") : null,
    spob: !store.toggleStudNo ? Yup.string().required("Required field") : null,
    semail: !store.toggleStudNo
      ? Yup.string().email("Email is invalid").required("Required field")
      : null,
    sphone: !store.toggleStudNo
      ? Yup.string().required("Required field")
      : null,
  });

  const handleNew = (props) => {
    dispatchStore(setToggleStudNo(false));
    props.values.sstudentNo = "";
    props.setTouched({});
  };

  const handleOld = (props) => {
    dispatchStore(setToggleStudNo(true));
  };

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadStudentInfo(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                <p className="form-title">
                  <strong>STUDENT INFORMATION</strong>
                </p>
                <p className="mb--40">
                  Enter the student’s legal surname (last name) and given names
                  below. These are the names on the student’s birth certificate
                  or adoption papers. A student cannot be registered without a
                  copy of a legal document that provides proof of legal name and
                  age.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label
                    className="label left"
                    htmlFor="gender"
                    style={{ marginRight: "20px" }}
                  >
                    Classification
                  </label>
                  <div className="radio-inline mb--20">
                    <div className="radio" style={{ marginTop: "0px" }}>
                      <MyRadio
                        type="radio"
                        name="sclassification"
                        value="new student - transferee"
                        id="idNew"
                        onClick={() => handleNew(props)}
                        // checked={store.toggleStudNo}
                      />
                      <label htmlFor="idNew">New Student / Transferee</label>
                    </div>
                    <div className="radio" style={{ marginTop: "0px" }}>
                      <MyRadio
                        type="radio"
                        name="sclassification"
                        value="Re-Enrolling"
                        id="idOld"
                        onClick={() => handleOld(props)}
                      />
                      <label htmlFor="idOld">Re-Enrolling</label>
                      <MyRadioError name="sclassification" />
                    </div>
                  </div>
                </div>
                {/* {store.toggleStudNo && (
                  <div className="form-group">
                    <InputText
                      label="Student No."
                      type="text"
                      id="student-number"
                      name="sstudentNo"
                    />
                  </div>
                )} */}
                <div className="form-group">
                  <MySelect
                    label="Grade Level (Incoming)"
                    id="idSelectStudentGradeLevel"
                    name="sgrade"
                  >
                    <option value=""> -- </option>
                    <option value="nursery">Nursery</option>
                    <option value="prekinder">Pre-Kinder</option>
                    <option value="kinder 2">Kinder 2</option>
                    <option value="grade 1">Grade 1</option>
                    <option value="grade 2">Grade 2</option>
                    <option value="grade 3">Grade 3</option>
                    <option value="grade 4">Grade 4</option>
                    <option value="grade 5">Grade 5</option>
                    <option value="grade 6">Grade 6</option>
                    <option value="grade 7">Grade 7</option>
                    <option value="grade 8">Grade 8</option>
                    <option value="grade 9">Grade 9</option>
                    <option value="grade 10">Grade 10</option>
                    <option value="grade 11">Grade 11</option>
                    <option value="grade 12">Grade 12</option>
                  </MySelect>
                </div>
                <div className="form-group">
                  <InputText
                    label="Learner Reference No."
                    type="text"
                    id="idLearnerRefNo"
                    name="slrn"
                    placeholder="Optional"
                  />
                </div>
                {!store.toggleStudNo && (
                  <div className="form-group">
                    <label
                      className="label left"
                      htmlFor="gender"
                      style={{ marginRight: "20px" }}
                    >
                      Gender
                    </label>
                    <div className="radio-inline mb--20">
                      <div className="radio" style={{ marginTop: "0px" }}>
                        <MyRadio
                          type="radio"
                          name="sgender"
                          value="male"
                          id="idMale"
                        />
                        <label htmlFor="idMale">Male</label>
                      </div>
                      <div className="radio" style={{ marginTop: "0px" }}>
                        <MyRadio
                          type="radio"
                          name="sgender"
                          value="female"
                          id="idFemale"
                        />
                        <label htmlFor="idFemale">Female</label>
                      </div>
                      <MyRadioError name="sgender" />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <InputText
                    label="First Name"
                    type="text"
                    id="idFname"
                    name="sfirstname"
                  />
                </div>{" "}
                <div className="form-group">
                  <InputText
                    label="Middle Name"
                    type="text"
                    id="idMname"
                    name="smiddlename"
                  />
                </div>
                <div className="form-group">
                  <InputText
                    label="Last Name"
                    type="text"
                    id="idLname"
                    name="slastname"
                  />
                </div>
                {!store.toggleStudNo && (
                  <>
                    <div className="form-group">
                      <InputText
                        label="Date of Birth"
                        type="date"
                        id="idDateBirth"
                        name="sdob"
                      />
                    </div>
                    <div className="form-group">
                      <InputText
                        label="Place of Birth"
                        type="text"
                        id="idPlaceBirth"
                        name="spob"
                      />
                    </div>
                    <div className="form-group">
                      <InputText
                        label="Email Address"
                        type="email"
                        id="idEmailAdd"
                        name="semail"
                      />
                    </div>
                    <div className="form-group">
                      <InputText
                        label="Phone"
                        type="phone"
                        id="idPhone"
                        name="sphone"
                      />
                    </div>
                  </>
                )}
                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>
                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentStudentInfoForm;
