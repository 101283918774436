import React from "react";
import HeaderAdmin from "../../HeaderAdmin";
import SideNavAdmin from "../../SideNavAdmin";
import { StoreContext } from "../../../store/StoreContext";
import Spinner from "../../modal/Spinner";
import ModalError from "../../modal/ModalError";
import { Link } from "react-router-dom";
import AdminTeacherTableList from "./AdminTeacherTableList";
// import SuccessNotif from "../../modal/SuccessNotif";

const AdminTeacher = (props) => {
  const { store } = React.useContext(StoreContext);

  return (
    <>
      <HeaderAdmin />

      <SideNavAdmin menu="teacher" />

      {store.loading && <Spinner />}

      {store.error && <ModalError />}
      <div className="main-content">
        <div className="student-block block">
          <div className="title">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <span className="tab-title">TEACHERS</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6">
                <Link
                  className="btn-green form-control right"
                  id="create-new"
                  to="/admin/teacher/add"
                >
                  <i className="fas fa-plus-circle"></i> Create New
                </Link>
              </div>
            </div>
          </div>
          {/* {store.success && <SuccessNotif msg="deleted" />} */}
          <div className="teacher content-block clearfix" id="teacher-list">
            {/* <form> */}
            {/* <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label className="">Type Search Keyword</label>
                <div className="search mb--20">
                  <input
                    type="text"
                    className="searchTerm"
                    placeholder="What are you looking for?"
                  />
                  <button type="submit" className="searchButton">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="border-bottom mb--20"></div> */}

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 table-col">
                <AdminTeacherTableList />
              </div>
            </div>

            {/* <div id="idRowPagi" class="row">
						<div id="pagi">
							<a href="#" id="btn_prev" class="btn-green form-control"><i class="fa fa-caret-left"></i></a>
							<span id="idPageNumber">1 of 2</span>
							<a href="#" id="btn_next" class="btn-green form-control"><i class="fa fa-caret-right"></i></a>
						</div>							
					</div>			 */}
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTeacher;
