import React from "react";
import { StoreContext } from "../../store/StoreContext";

const ErrorNotif = ({ msg }) => {
  // const { serverError } = msg;
  const { store } = React.useContext(StoreContext);
  return (
    <>
      <div className="notify-bar notify-show notify-warning mb--20">
        <div
          className="notify-bar-text t-center"
          style={{ padding: "10px", fontSize: "0.8rem" }}
        >
          <p className="no-mg t-left">
            <span className="fas fa-exclamation-circle"></span> {store.message}
          </p>
        </div>
      </div>
    </>
  );
};

export default ErrorNotif;
