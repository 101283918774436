import React from "react";
import { StoreContext } from "../../store/StoreContext";
import { loadError } from "../../store/StoreActions";

const ModalError = () => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const handleClick = (e) => {
    dispatchStore(loadError(false));
    // window.location.reload();
  };

  return (
    <>
      <div className="error-message">
        <img
          src="../../../img/times-solid.svg"
          alt="error icon"
          className="btn--close"
          onClick={handleClick}
        />
        <div className="error-box">
          <img
            src="../../../img/exclamation-triangle-solid.svg"
            alt="triangle error icon"
            className="mb--30"
          />
          <h2>
            <strong>Something went wrong</strong>
          </h2>
          <p className="mb--40">{store.message}</p>
          {/* <a id="smooth-scroll" href="index.html" className="btn--green">
            CONTINUE
          </a> */}
        </div>
      </div>
    </>
  );
};

export default ModalError;
