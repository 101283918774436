import React from "react";
import HeaderEnrollment from "./HeaderEnrollment";
import FooterEnrollment from "./FooterEnrollment";

const EnrollmentThankyou = (props) => {
  const storage = localStorage.getItem("sv");

  React.useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
    }, 1000);
  }, []);
  return (
    <>
      {storage ? (
        <>
          <section className="form-container bg-gray">
            <div className="container">
              <HeaderEnrollment />

              <div className="bg-white">
                <div id="id-form-student" className="form-content">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="content t-center mb--40">
                        <img
                          src="../../../img/thankyou.png"
                          alt="thank you"
                          className="mb--20"
                        />
                        <p>
                          Once you are successfully enrolled, you will receive
                          an email from Frontline Christian Academy.
                        </p>
                      </div>

                      <div className="form-group t-center clearfix">
                        <a
                          href="https://fca.edu.ph/"
                          className="btn-green form-control btn-next"
                          id="view-parent"
                          name="btnSubmit"
                        >
                          GO TO HOME PAGE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterEnrollment />
        </>
      ) : (
        <p>The page you're trying to view has expired or invalid.</p>
      )}
    </>
  );
};

export default EnrollmentThankyou;
