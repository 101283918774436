import { uuid } from "uuidv4";
import {
  setResidence,
  setSchoolHistory,
  setStudentInfo,
  setMedicalInfo,
  setParentInfoFirst,
  setParentInfoSecond,
  setEmergencyContact,
  setFinancial,
  setDocs,
  setpfAddress,
  setpsAddress,
  setStudentData,
  loadSpinner,
  loadError,
  loadMessage,
  loadSuccess,
  setStudName,
  loadInfo,
  setConsentParentName,
  setConsentStudName,
  setConsentDecName,
} from "../store/StoreActions";
import { postData } from "../ajax/RequestMethod";
// import { SendEmailReceipt } from "../components/api/SendEmailReceipt";

// await new Promise((resolve) => setTimeout(resolve, 1000));
// alert(JSON.stringify(values, null, 2));

// export const baseUrl = "https://smis.fca.edu.ph/";
export const baseUrl = "https://smis.frontlineacademy.asia/";

export function setStorage() {
  const uid = uuid();
  localStorage.setItem("sv", uid);
}

export function setStorageRoute(jwt) {
  localStorage.setItem("token", jwt);
}

// export const sendEmail = async (values) => {
//   const resEmail = await SendEmailReceipt(values);
//   console.log(resEmail);
// };

export function scrollPageUp() {
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;
  document
    .querySelector(`.main-top`)
    .scrollIntoView({ behavior: "smooth", block: "start" });
}

export const capitalFirstLetter = (obj) => {
  return obj.charAt(0).toUpperCase() + obj.substring(1);
};

export const getStudentGrade = (obj) => {
  return document.querySelector("#idStudent_grade").value;
};

export const getStudentSy = (obj) => {
  return document.querySelector("#idStudent_sy").value;
};

export const postDataStudent = async (
  url,
  values = null,
  dispatchStore,
  actions = ""
) => {
  dispatchStore(loadSuccess(false));
  dispatchStore(loadInfo(false));
  dispatchStore(loadSpinner(true));
  // do ajax
  const student = await postData(url, values, dispatchStore);
  // When catch error
  if (typeof student === "undefined") {
    dispatchStore(loadSpinner(false));
    return actions.resetForm();
  }

  console.log(student.data);

  // when no records found
  if (student.data.data.length === 0) {
    dispatchStore(setStudentData([]));
    dispatchStore(loadSpinner(false));
    return;
  }

  // when editing records found
  if (student.data.data === true) {
    dispatchStore(loadSpinner(false));
    dispatchStore(loadSuccess(true));
    dispatchStore(loadInfo(true));
    return;
  }

  // when API Error ocurred
  if (!student.data.status) {
    dispatchStore(loadError(true));
    dispatchStore(loadMessage(student.data.message));
    dispatchStore(loadSpinner(false));
    return;
  }

  // when records are found
  dispatchStore(setStudentData(student.data.data));
  dispatchStore(loadSpinner(false));
};

export const fetchData = (url, fd = {}, dispatchStore) => {
  dispatchStore(loadError(false));
  dispatchStore(loadSuccess(false));
  // dispatchStore(loadSpinner(true));
  const data = fetch(url, {
    method: "post",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(fd),
  })
    .then((res) => res.json())
    // .then((data) => {
    //   console.log(data);
    //   dispatchStore(loadSpinner(false));
    //   dispatchStore(loadSuccess(true));
    // })
    .catch((error) => {
      console.error("API Error");
      dispatchStore(loadSpinner(false));
      dispatchStore(loadError(true));
      dispatchStore(
        loadMessage(
          "Network error. Make sure you are connected to the internet or you may contact patrick.reyes@frontlinebusiness.com.ph"
        )
      );
    });
  return data;
};

export const handlepfAddressYes = (dispatchStore, props) => {
  dispatchStore(setpfAddress(false));
  props.values.pfaddress = "";
  props.values.pfcity = "";
  props.values.pfprovince = "";
  props.values.pfpostal = "";
};

export const handlepfAddressNo = (dispatchStore) => {
  dispatchStore(setpfAddress(true));
};

export const handlepsAddressYes = (dispatchStore, props) => {
  dispatchStore(setpsAddress(false));
  props.values.psaddress = "";
  props.values.pscity = "";
  props.values.psprovince = "";
  props.values.pspostal = "";
};

export const handlepsAddressNo = (dispatchStore) => {
  dispatchStore(setpsAddress(true));
};

export function setStorageData(name, amount, purpose, desc) {
  localStorage.setItem(
    "data",
    JSON.stringify({
      name: name,
      date: new Date().toLocaleDateString(),
      amount: amount,
      purpose: purpose,
      description: desc,
    })
  );
}

export const loadStudentInfo = (dispatchStore, values) => {
  dispatchStore(
    setStudentInfo(
      values.sclassification,
      values.ssy,
      values.slrn,
      values.sstudentNo,
      values.sgrade,
      values.sgender,
      values.sfirstname,
      values.smiddlename,
      values.slastname,
      values.sdob,
      values.spob,
      values.semail,
      values.sphone,
      values.sphoto
    )
  );
};

export const loadResidence = (dispatchStore, values) => {
  dispatchStore(
    setResidence(
      values.rcurrentAddress,
      values.rcity,
      values.rprovince,
      values.rpostal
    )
  );
};

export const loadSchoolHistory = (dispatchStore, values) => {
  dispatchStore(
    setSchoolHistory(
      values.shschool,
      values.shschoolAddress,
      values.shgpa,
      values.shdisciplinary
    )
  );
};

export const loadMedicalInfo = (dispatchStore, values) => {
  dispatchStore(
    setMedicalInfo(
      values.mnotes,
      values.mdoctor,
      values.mcontactNo,
      values.mcircumstance
    )
  );
};

export const loadParentFirst = (dispatchStore, values) => {
  dispatchStore(
    setParentInfoFirst(
      values.pfrelation,
      values.pflastname,
      values.pffirstname,
      values.pfaddress,
      values.pfcity,
      values.pfprovince,
      values.pfpostal,
      values.pfresideWith,
      values.pfphone,
      values.pfmobile,
      values.pfdob,
      values.pfemail,
      values.pfreligion,
      values.pfoccupation
    )
  );
};

export const loadParentSecond = (dispatchStore, values) => {
  dispatchStore(
    setParentInfoSecond(
      values.psrelation,
      values.pslastname,
      values.psfirstname,
      values.psaddress,
      values.pscity,
      values.psprovince,
      values.pspostal,
      values.psresideWith,
      values.psphone,
      values.psmobile,
      values.psdob,
      values.psemail,
      values.psreligion,
      values.psoccupation
    )
  );
};

export const loadEmergencyContact = (dispatchStore, values) => {
  dispatchStore(
    setEmergencyContact(
      values.emprimary,
      values.emptel,
      values.empotherPhone,
      values.emsecondary,
      values.emstel,
      values.emsotherPhone
    )
  );
};

export const loadFinancial = (dispatchStore, values) => {
  dispatchStore(
    setFinancial(
      values.fifatherIncome,
      values.fiMotherIncome,
      values.fieduc
      // values.fischeme,
      // values.fiother
    )
  );
};

export const loadDocs = (dispatchStore, values) => {
  dispatchStore(setStudName(values.student_name));
  dispatchStore(setDocs(values.decname));
};

export const loadConsent = (dispatchStore, values) => {
  dispatchStore(setConsentParentName(values.consent_parent_name));
  dispatchStore(setConsentStudName(values.consent_student_name));
  dispatchStore(setConsentDecName(values.consent_decname));
};

export const getTodayYear = () => {
  const today = new Date();
  const year = today.getFullYear();
  const sy = [];
  let nYear = year;
  let x = 1;
  for (let i = 0; i <= 50; i++) {
    if (i === 0) {
      nYear = year + 1;
    }

    if (i === 1) {
      nYear = year;
    }

    if (i >= 2) {
      nYear = year - x;
      x++;
    }

    sy.push(`${year - i}-${nYear}`);
  }

  return sy;
};
