export const StoreReducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "INFO":
      return {
        ...state,
        info: action.payload,
      };
    case "SUCCESS":
      return {
        ...state,
        success: action.payload,
      };
    case "MESSAGE":
      return {
        ...state,
        message: action.payload,
      };
    case "IS_DEL":
      return {
        ...state,
        isDel: action.payload,
      };
    case "IS_DEL_STUDENT":
      return {
        ...state,
        isDelStudent: action.payload,
      };
    case "IS_DEL_TEACHER":
      return {
        ...state,
        isDelTeacher: action.payload,
      };
    case "DEL_ID":
      return {
        ...state,
        delId: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        logout: action.payload,
      };
    case "SERVER_ERROR":
      return {
        ...state,
        serverError: action.payload,
      };
    case "SERVER_ERROR_MSG":
      return {
        ...state,
        serverErrorMsg: action.payload,
      };
    case "USER_EMAIL":
      return {
        ...state,
        userEmail: action.payload,
      };
    case "PAGE_NUM":
      return {
        ...state,
        pageNum: action.payload,
      };
    case "TOGGLE_STUD_NO":
      return {
        ...state,
        toggleStudNo: action.payload,
      };
    case "SAME_PFADDRESS":
      return {
        ...state,
        ispfSameAddress: action.payload,
      };
    case "SAME_PSADDRESS":
      return {
        ...state,
        ispsSameAddress: action.payload,
      };
    case "FEE":
      return {
        ...state,
        fee: action.payload,
      };
    case "GRADE_INDEX":
      return {
        ...state,
        gradeIndex: action.payload,
      };
    case "STUDENT_DATA":
      return {
        ...state,
        studentData: action.payload,
      };
    case "STUDENT_DATA_EDIT":
      return {
        ...state,
        studentDataEdit: action.payload,
      };
    case "TEACHER_DATA_EDIT":
      return {
        ...state,
        teacherDataEdit: action.payload,
      };
    case "STUDENT_INFO":
      return {
        ...state,
        studentInfo: {
          sclassification: action.payload.sclassification,
          ssy: action.payload.ssy,
          slrn: action.payload.slrn,
          sstudentNo: action.payload.sstudentNo,
          sgrade: action.payload.sgrade,
          sgender: action.payload.sgender,
          sfirstname: action.payload.sfirstname,
          smiddlename: action.payload.smiddlename,
          slastname: action.payload.slastname,
          sdob: action.payload.sdob,
          spob: action.payload.spob,
          semail: action.payload.semail,
          sphone: action.payload.sphone,
          sphoto: action.payload.sphoto,
        },
      };
    case "RESIDENCE":
      return {
        ...state,
        residence: {
          rcurrentAddress: action.payload.rcurrentAddress,
          rcity: action.payload.rcity,
          rprovince: action.payload.rprovince,
          rpostal: action.payload.rpostal,
        },
      };
    case "SCHOOL_HISTORY":
      return {
        ...state,
        schoolHistory: {
          shschool: action.payload.shschool,
          shschoolAddress: action.payload.shschoolAddress,
          shgpa: action.payload.shgpa,
          shdisciplinary: action.payload.shdisciplinary,
        },
      };
    case "MEDICAL_INFO":
      return {
        ...state,
        medicalInfo: {
          mnotes: action.payload.mnotes,
          mdoctor: action.payload.mdoctor,
          mcontactNo: action.payload.mcontactNo,
          mcircumstance: action.payload.mcircumstance,
        },
      };
    case "PARENT_FIRST":
      return {
        ...state,
        parentInfoFirst: {
          pfrelation: action.payload.pfrelation,
          pflastname: action.payload.pflastname,
          pffirstname: action.payload.pffirstname,
          pfaddress: action.payload.pfaddress,
          pfcity: action.payload.pfcity,
          pfprovince: action.payload.pfprovince,
          pfpostal: action.payload.pfpostal,
          pfresideWith: action.payload.pfresideWith,
          pfphone: action.payload.pfphone,
          pfmobile: action.payload.pfmobile,
          pfdob: action.payload.pfdob,
          pfemail: action.payload.pfemail,
          pfreligion: action.payload.pfreligion,
          pfoccupation: action.payload.pfoccupation,
        },
      };
    case "PARENT_SECOND":
      return {
        ...state,
        parentInfoSecond: {
          psrelation: action.payload.psrelation,
          pslastname: action.payload.pslastname,
          psfirstname: action.payload.psfirstname,
          psaddress: action.payload.psaddress,
          pscity: action.payload.pscity,
          psprovince: action.payload.psprovince,
          pspostal: action.payload.pspostal,
          psresideWith: action.payload.psresideWith,
          psphone: action.payload.psphone,
          psmobile: action.payload.psmobile,
          psdob: action.payload.psdob,
          psemail: action.payload.psemail,
          psreligion: action.payload.psreligion,
          psoccupation: action.payload.psoccupation,
        },
      };
    case "EMERGENCY_CONTACT":
      return {
        ...state,
        emergencyContact: {
          emprimary: action.payload.emprimary,
          emptel: action.payload.emptel,
          empotherPhone: action.payload.empotherPhone,
          emsecondary: action.payload.emsecondary,
          emstel: action.payload.emstel,
          emsotherPhone: action.payload.emsotherPhone,
        },
      };
    case "FINANCIAL":
      return {
        ...state,
        financialInfo: {
          fifatherIncome: action.payload.fifatherIncome,
          fiMotherIncome: action.payload.fiMotherIncome,
          fieduc: action.payload.fieduc,
          // fischeme: action.payload.fischeme,
          // fiother: action.payload.fiother,
        },
      };
    case "DECNAME":
      return {
        ...state,
        decname: action.payload,
      };
    case "STUDNAME":
      return {
        ...state,
        student_name: action.payload,
      };
    case "CONSENT_PARENT_NAME":
      return {
        ...state,
        consent_parent_name: action.payload,
      };
    case "CONSENT_STUDENT_NAME":
      return {
        ...state,
        consent_student_name: action.payload,
      };
    case "CONSENT_DEC_NAME":
      return {
        ...state,
        consent_decname: action.payload,
      };
    default:
      return state;
  }
};
