import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { StoreContext } from "../../store/StoreContext";
import ErrorNotif from "../modal/ErrorNotif";
import queryString from "query-string";
// import { postData } from "../../ajax/RequestMethod";
// import { loadError, loadMessage } from "../../store/StoreActions";
import { InputText } from "../../module/ModuleFormFields";
import { postData } from "../../ajax/RequestMethod";
import Spinner from "../modal/Spinner";
import { baseUrl, setStorage } from "../../module/functions";

const CreatePass = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const btnStyle = {
    width: "100%",
    display: "block",
    borderRadius: "50px",
  };

  const logoStyle = {
    height: "auto",
    marginBottom: "20px",
  };

  const initVal = {
    password: "",
  };

  const yupSchema = Yup.object({
    password: Yup.string()
      .min(6, "Your password must be at least 6 characters.")
      .required("Required field"),
  });

  const param = queryString.parse(props.location.search);
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const createPass = async () => {
      const key = { key: param.key };
      const verifyData = await postData(
        baseUrl + "rest/users/read-user-reset-pass-key-activate.php",
        key,
        dispatchStore
      );
      console.log(verifyData);
      const verifyStatus = verifyData.data.status;
      verifyStatus && setValid(true);
      setLoading(false);
    };
    createPass();
  }, [dispatchStore, param.key]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : valid ? (
        <div className="container main-top" id="login">
          <div className="content-block no-mg no-pd">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-4 bg-white"
                id="login-input"
              >
                <div className="">
                  <div className="logo t-center">
                    <img
                      src="img/fca-logo.png"
                      alt="fca logo"
                      className="mb--10"
                    />
                  </div>
                  <div className="logo t-center" style={logoStyle}>
                    <span>CREATE NEW PASSWORD</span>
                  </div>
                  {store.error && <ErrorNotif />}
                  <Formik
                    initialValues={initVal}
                    validationSchema={yupSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const activateData = await postData(
                        baseUrl + "rest/users/update-user-pass.php",
                        { key: param.key, ...values },
                        dispatchStore
                      );
                      console.log(activateData.data);
                      // redirect to  verify page
                      setStorage();
                      window.location.replace("/new-password-success");
                    }}
                  >
                    {(props) => {
                      // console.log(props.isSubmitting);

                      return (
                        <Form
                          name="nameFormRequest"
                          id="idFormRequest"
                          className=""
                        >
                          <div className="icon-container mb--30 t-center">
                            <InputText
                              type="password"
                              placeholder="New Password"
                              name="password"
                              disabled={props.isSubmitting}
                            />
                            <i className="fas fa-envelope"></i>
                          </div>

                          <button
                            type="submit"
                            id="idBtnRequest"
                            className="btn-green form-control t-center"
                            style={btnStyle}
                            disabled={
                              !props.dirty ||
                              !props.isValid ||
                              props.isSubmitting
                            }
                          >
                            SUBMIT{" "}
                            {props.isSubmitting && (
                              <i className="fas fa-spinner fa-spin fa-1x"></i>
                            )}
                          </button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>The page you're trying to view has expired or invalid.</p>
      )}
    </>
  );
};

export default CreatePass;
