import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import {
  InputText,
  MyRadioError,
  MyRadio,
} from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import { loadFinancial, scrollPageUp } from "../../module/functions";
import { setPageNum, setFee, loadInfo } from "../../store/StoreActions";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentFinancialForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  React.useEffect(() => {
    if (store.studentInfo.sclassification === "Re-Enrolling") {
      switch (store.studentInfo.sgrade) {
        case "nursery":
          dispatchStore(setFee("nurseryfees.jpg"));
          break;
        case "prekinder":
          dispatchStore(setFee("prekinderfees.jpg"));
          break;
        case "kinder 2":
          dispatchStore(setFee("kinderfees.jpg"));
          break;
        case "grade 1":
          dispatchStore(setFee("g1fees.jpg"));
          break;
        case "grade 2":
          dispatchStore(setFee("g2fees.jpg"));
          break;
        case "grade 3":
          dispatchStore(setFee("g3fees.jpg"));
          break;
        case "grade 4":
          dispatchStore(setFee("g4fees.jpg"));
          break;
        case "grade 5":
          dispatchStore(setFee("g5fees.jpg"));
          break;
        case "grade 6":
          dispatchStore(setFee("g6fees.jpg"));
          break;
        case "grade 7":
          dispatchStore(setFee("g7fees.jpg"));
          break;
        case "grade 8":
          dispatchStore(setFee("g8fees.jpg"));
          break;
        case "grade 9":
          dispatchStore(setFee("g9fees.jpg"));
          break;
        case "grade 10":
          dispatchStore(setFee("g10fees.jpg"));
          break;
        default:
          dispatchStore(setFee(""));
          break;
      }
    } else {
      switch (store.studentInfo.sgrade) {
        case "nursery":
          dispatchStore(setFee("nurseryfeesN.jpg"));
          break;
        case "prekinder":
          dispatchStore(setFee("prekinderfeesN.jpg"));
          break;
        case "kinder 2":
          dispatchStore(setFee("kinderfeesN.jpg"));
          break;
        case "grade 1":
          dispatchStore(setFee("g1feesN.jpg"));
          break;
        case "grade 2":
          dispatchStore(setFee("g2feesN.jpg"));
          break;
        case "grade 3":
          dispatchStore(setFee("g3feesN.jpg"));
          break;
        case "grade 4":
          dispatchStore(setFee("g4feesN.jpg"));
          break;
        case "grade 5":
          dispatchStore(setFee("g5feesN.jpg"));
          break;
        case "grade 6":
          dispatchStore(setFee("g6feesN.jpg"));
          break;
        case "grade 7":
          dispatchStore(setFee("g7feesN.jpg"));
          break;
        case "grade 8":
          dispatchStore(setFee("g8feesN.jpg"));
          break;
        case "grade 9":
          dispatchStore(setFee("g9feesN.jpg"));
          break;
        case "grade 10":
          dispatchStore(setFee("g10feesN.jpg"));
          break;
        default:
          dispatchStore(setFee(""));
          break;
      }
    }
  }, [dispatchStore, store.studentInfo]);

  let yupSchema = "";

  const initVal = {
    fifatherIncome: store.financialInfo.fifatherIncome,
    fiMotherIncome: store.financialInfo.fiMotherIncome,
    fieduc: store.financialInfo.fieduc,
    // fischeme: store.financialInfo.fischeme,
    // fiother: store.financialInfo.fiother,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      fifatherIncome: Yup.string().required("Required field"),
      fiMotherIncome: Yup.string().required("Required field"),
      fieduc: Yup.string().required("Required field"),
      // fischeme: Yup.string().required("Required field"),
      // fiother: Yup.string().required("Required field"),
    });
  } else {
    yupSchema = Yup.object({
      // fischeme: Yup.string().required("Required field"),
      // fiother: store.serverError
      //   ? Yup.string().required("Required field")
      //   : null,
    });
  }

  const clearText = (props) => {
    props.values.fiother = "";
  };

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadFinancial(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                <p className="form-title">
                  <strong>FINANCIAL INFORMATION</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {!store.toggleStudNo && (
                  <>
                    <div className="form-group">
                      <InputText
                        label="Father's Gross Monthly Income"
                        type="text"
                        id="idFatherIncome"
                        name="fifatherIncome"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="Mother's Gross Monthly Income:"
                        type="text"
                        id="idMotherFinancer"
                        name="fiMotherIncome"
                      />
                    </div>
                    <div className="form-group">
                      <InputText
                        label="Who will be financing the child's education?"
                        type="text"
                        id="idParentFinancer"
                        name="fieduc"
                      />
                    </div>
                  </>
                )}
                {/* <div className="form-group">
                  <p>
                    <strong>PAYMENT SCHEME</strong>
                  </p>
                  <p>
                    Please refer to the table of payment schemes below and
                    choose one (1).
                  </p>
                  <div className="radio-inline mb--20">
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        id="scheme-a"
                        value="Scheme A"
                        name="fischeme"
                        onClick={() => clearText(props)}
                      />
                      <label htmlFor="scheme-a">Scheme A</label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        id="scheme-b"
                        value="Scheme B"
                        name="fischeme"
                        onClick={() => clearText(props)}
                      />
                      <label htmlFor="scheme-b">Scheme B</label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        id="scheme-c"
                        value="Scheme C"
                        name="fischeme"
                        onClick={() => clearText(props)}
                      />
                      <label htmlFor="scheme-c">Scheme C</label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        id="scheme-d"
                        value="Scheme D"
                        name="fischeme"
                        onClick={() => clearText(props)}
                      />
                      <label htmlFor="scheme-d">Scheme D</label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        id="scheme-o"
                        value="Scheme O"
                        name="fischeme"
                      />
                      <label htmlFor="scheme-o">Other (Please specify)</label>
                    </div>
                    <MyRadioError name="fischeme" />
                    <div className="radio">
                      <InputText type="text" id="schemeOther" name="fiother" />
                    </div>
                  </div>
                  <img
                    src={`../../../img/${store.fee}`}
                    alt="payment shceme"
                  ></img>
                </div> */}
                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>
                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentFinancialForm;
