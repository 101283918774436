import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import { InputText, MyTextArea } from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import { loadMedicalInfo, scrollPageUp } from "../../module/functions";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import WarningNotif from "../modal/WarningNotif";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentMedicalForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  let yupSchema = "";

  const initVal = {
    mnotes: store.medicalInfo.mnotes,
    mdoctor: store.medicalInfo.mdoctor,
    mcontactNo: store.medicalInfo.mcontactNo,
    mcircumstance: store.medicalInfo.mcircumstance,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      mnotes: Yup.string().required("Required field"),
      mdoctor: Yup.string().required("Required field"),
      // contactNo: Yup.string().required("Required field"),
      // mcircumstance: Yup.string().required("Required field"),
    });
  }

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadMedicalInfo(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                {store.toggleStudNo && <WarningNotif />}
                <p className="form-title">
                  <strong>MEDICAL INFORMATION</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label>
                    Are there any serious medical conditions about which you
                    wish the school to be aware? Please indicate below:
                  </label>
                  <MyTextArea
                    label="Medical Notes"
                    name="mnotes"
                    id="idMedicalNotes"
                    cols="30"
                    rows="10"
                  ></MyTextArea>
                </div>

                <div className="form-group">
                  <InputText
                    label="Pediatrician/Family Doctor"
                    type="text"
                    id="idFamilyDoc"
                    name="mdoctor"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Contact Number"
                    type="text"
                    id="idContactNoDoc"
                    name="mcontactNo"
                    placeholder="Optional"
                  />
                </div>
              </div>
            </div>
            <div className="border-bottom-thick mb--40"></div>
            <div className="row">
              <div className="col-lg-12">
                <p className="form-title">
                  <strong>FAMILY CIRCUMSTANCES</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <MyTextArea
                    label="Are there any family circumstances about which you wish the school to be aware?"
                    name="mcircumstance"
                    id="idCircumstances"
                    cols="30"
                    rows="10"
                    placeholder="If there's any"
                  ></MyTextArea>
                </div>
                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>

                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentMedicalForm;
