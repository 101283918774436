import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import {
  InputText,
  MyRadio,
  MyRadioError,
} from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import {
  loadParentFirst,
  scrollPageUp,
  handlepfAddressYes,
  handlepfAddressNo,
} from "../../module/functions";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import WarningNotif from "../modal/WarningNotif";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentParentFirstForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  let yupSchema = "";

  const initVal = {
    pfrelation: store.parentInfoFirst.pfrelation,
    pflastname: store.parentInfoFirst.pflastname,
    pffirstname: store.parentInfoFirst.pffirstname,
    pfaddress: store.parentInfoFirst.pfaddress,
    pfcity: store.parentInfoFirst.pfcity,
    pfprovince: store.parentInfoFirst.pfprovince,
    pfpostal: store.parentInfoFirst.pfpostal,
    pfresideWith: store.parentInfoFirst.pfresideWith,
    pfphone: store.parentInfoFirst.pfphone,
    pfmobile: store.parentInfoFirst.pfmobile,
    pfdob: store.parentInfoFirst.pfdob,
    pfemail: store.parentInfoFirst.pfemail,
    pfreligion: store.parentInfoFirst.pfreligion,
    pfoccupation: store.parentInfoFirst.pfoccupation,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      pfrelation: Yup.string().required("Required field"),
      pflastname: Yup.string().required("Required field"),
      pffirstname: Yup.string().required("Required field"),
      pfaddress: store.ispfSameAddress
        ? Yup.string().required("Required field")
        : null,
      pfcity: store.ispfSameAddress
        ? Yup.string().required("Required field")
        : null,
      pfprovince: store.ispfSameAddress
        ? Yup.string().required("Required field")
        : null,
      pfpostal: store.ispfSameAddress
        ? Yup.string().required("Required field")
        : null,
      pfresideWith: Yup.string().required("Required field"),
      // pfphone: Yup.string().required("Required field"),
      pfmobile: Yup.string().required("Required field"),
      pfdob: Yup.string().required("Required field"),
      pfemail: Yup.string()
        .email("Email is invalid")
        .required("Required field"),
      pfreligion: Yup.string().required("Required field"),
      pfoccupation: Yup.string().required("Required field"),
    });
  }

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadParentFirst(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                {store.toggleStudNo && <WarningNotif />}
                <p className="form-title">
                  <strong>PARENT OR GUARDIAN INFORMATION</strong>
                </p>
                <p>
                  <strong>FIRST PARENT/GUARDIAN</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="label">
                    Relationship to Student: (please select one)
                  </label>
                  <div className="radio-inline mb--20">
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        name="pfrelation"
                        value="biological-1"
                        id="idFirstParentBiological"
                      />
                      <label htmlFor="idFirstParentBiological">
                        Biological or adoptive mother
                      </label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        name="pfrelation"
                        value="stepmother-1"
                        id="idFirstParentStepMother"
                      />
                      <label htmlFor="idFirstParentStepMother">
                        Step-mother
                      </label>
                    </div>
                    <div className="radio">
                      <MyRadio
                        type="radio"
                        name="pfrelation"
                        value="other-1"
                        id="idFirstParentOther"
                      />
                      <label htmlFor="idFirstParentOther">Other</label>
                    </div>
                    <MyRadioError name="pfrelation" />
                  </div>
                </div>

                <div className="form-group">
                  <InputText
                    label="Last Name"
                    type="text"
                    id="idFirstParentLastname"
                    name="pflastname"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="First Name"
                    type="text"
                    id="idFirstParentFirstname"
                    name="pffirstname"
                  />
                </div>

                <div className="form-group">
                  <label className="label" htmlFor="gender">
                    Does the student reside with this individual?
                  </label>
                  <div className="radio-inline mb--20">
                    <div className="radio">
                      <MyRadio
                        label="Yes"
                        type="radio"
                        id="idFirstParentResideWithYes"
                        name="pfresideWith"
                        value="yes"
                        onClick={() => handlepfAddressYes(dispatchStore, props)}
                      />
                    </div>
                    <div className="radio">
                      <MyRadio
                        label="No"
                        type="radio"
                        id="idFirstParentResideWithNo"
                        name="pfresideWith"
                        value="no"
                        onClick={() => handlepfAddressNo(dispatchStore)}
                      />
                    </div>
                    <MyRadioError name="pfresideWith" />
                  </div>
                </div>
                {store.ispfSameAddress && (
                  <>
                    <div className="form-group">
                      <InputText
                        label="Address (if different from student's):"
                        type="text"
                        id="idFirstParentAddress"
                        name="pfaddress"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="City"
                        type="text"
                        id="idFirstParentCity"
                        name="pfcity"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="Province"
                        type="text"
                        id="idFirstParentProvince"
                        name="pfprovince"
                      />
                    </div>

                    <div className="form-group">
                      <InputText
                        label="Postal"
                        type="text"
                        id="idFirstParentPostal"
                        name="pfpostal"
                      />
                    </div>
                  </>
                )}
                <div className="form-group">
                  <InputText
                    label="Home Phone No."
                    type="text"
                    id="idFirstParentPhone"
                    name="pfphone"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Mobile Phone No."
                    type="text"
                    id="idFirstParentMobile"
                    name="pfmobile"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Birth Date"
                    type="date"
                    id="idFirstParentBirthday"
                    name="pfdob"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Email"
                    type="email"
                    id="idFirstParentEmail"
                    name="pfemail"
                    // placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Religion"
                    type="text"
                    id="idFirstParentReligion"
                    name="pfreligion"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Occupation"
                    type="text"
                    id="idFirstParentOccupation"
                    name="pfoccupation"
                  />
                </div>

                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>

                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentParentFirstForm;
