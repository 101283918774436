import React from "react";
import { Link } from "react-router-dom";

const SideNavAdmin = ({ menu }) => {
  return (
    <div className="side-nav">
      <div className="logo">
        <img src="../../img/fca-logo-light2.png" alt="" />
        <span>FCA SMIS</span>
      </div>
      <nav>
        <ul>
          {/* <li className="" id="menu-dashboard">
            <a href="dashboard.html">
              <span>
                <i className="fas fa-tachometer-alt"></i>
              </span>
              <span>Dashboard</span>
            </a>
          </li> */}

          <li className={menu === "student" ? "active" : ""} id="menu-student">
            <Link to="/admin/student">
              <span>
                <i className="fa fa-user"></i>
              </span>
              <span>Students</span>
            </Link>
          </li>

          <li className={menu === "teacher" ? "active" : ""} id="menu-teacher">
            <Link to="/admin/teacher">
              <span>
                <i className="fas fa-chalkboard-teacher"></i>
              </span>
              <span>Teachers</span>
            </Link>
          </li>

          {/* <li className="" id="menu-parent">
            <a href="parent.html">
              <span>
                <i className="fas fa-user-friends"></i>
              </span>
              <span>Parents</span>
            </a>
          </li>

          <li className="" id="menu-className">
            <a href="className.html">
              <span>
                <i className="fas fa-users"></i>
              </span>
              <span>ClassNamees</span>
            </a>
          </li>

          <li className="" id="menu-subject">
            <a href="subject.html">
              <span>
                <i className="fas fa-clipboard-list"></i>
              </span>
              <span>Subjects</span>
            </a>
          </li>

          <li className="" id="menu-grade">
            <a href="grade.html">
              <span>
                <i className="fas fa-id-badge"></i>
              </span>
              <span>Grades</span>
            </a>
          </li>

          <li className="" id="menu-notify">
            <a href="notify.html">
              <span>
                <i className="fas fa-bullhorn"></i>
              </span>
              <span>Notifications</span>
            </a>
          </li>

          <li className="" id="menu-payment">
            <a href="payment.html">
              <span>
                <i className="fas fa-credit-card"></i>
              </span>
              <span>Payments</span>
            </a>
          </li>

          <li className="" id="menu-user">
            <a href="user.html">
              <span>
                <i className="fas fa-users-cog"></i>
              </span>
              <span>Users</span>
            </a>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default SideNavAdmin;
