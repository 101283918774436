import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import HeaderAdmin from "../../HeaderAdmin";
import SideNavAdmin from "../../SideNavAdmin";
import {
  InputText,
  MyRadio,
  MyRadioError,
  MyTextArea,
  MyCheckbox,
} from "../../../module/ModuleFormFields";
import { StoreContext } from "../../../store/StoreContext";
import { scrollPageUp, fetchData, baseUrl } from "../../../module/functions";
import Spinner from "../../modal/Spinner";
import SuccessNotif from "../../modal/SuccessNotif";
import RequiredNotif from "../../modal/RequiredNotif";
import ModalError from "../../modal/ModalError";
import {
  loadError,
  loadMessage,
  loadSpinner,
  loadSuccess,
} from "../../../store/StoreActions";

const AdminTeacherEdit = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  React.useEffect(() => {
    if (store.teacherDataEdit.length === 0) {
      props.history.push("/admin/teacher");
      return;
    }
  }, [store.teacherDataEdit.length, props.history]);

  const initVal = {
    teacher_id: store.teacherDataEdit.teacher_id,
    teacher_date_reg: store.teacherDataEdit.teacher_date_reg,
    teacher_gender: store.teacherDataEdit.teacher_gender,
    teacher_email: store.teacherDataEdit.teacher_email,
    teacher_lname: store.teacherDataEdit.teacher_lname,
    teacher_fname: store.teacherDataEdit.teacher_fname,
    teacher_mname: store.teacherDataEdit.teacher_mname,
    teacher_dob: store.teacherDataEdit.teacher_dob,
    teacher_pob: store.teacherDataEdit.teacher_pob,
    teacher_phone: store.teacherDataEdit.teacher_phone,
    teacher_photo: store.teacherDataEdit.teacher_photo,

    teacher_address: store.teacherDataEdit.teacher_address,
    teacher_city: store.teacherDataEdit.teacher_city,
    teacher_province: store.teacherDataEdit.teacher_province,
    teacher_postal: store.teacherDataEdit.teacher_postal,

    teacher_code: store.teacherDataEdit.teacher_code,
    teacher_hours: store.teacherDataEdit.teacher_hours,
    teacher_start_date: store.teacherDataEdit.teacher_start_date,
    teacher_end_date: store.teacherDataEdit.teacher_end_date,
    teacher_position: store.teacherDataEdit.teacher_position,

    teacher_sss: store.teacherDataEdit.teacher_sss,
    teacher_phil_health: store.teacherDataEdit.teacher_phil_health,
    teacher_tax_id: store.teacherDataEdit.teacher_tax_id,
    teacher_pagibig: store.teacherDataEdit.teacher_pagibig,
    teacher_diploma: Boolean(store.teacherDataEdit.teacher_diploma),
    teacher_tor: Boolean(store.teacherDataEdit.teacher_tor),
    teacher_rec_letter: Boolean(store.teacherDataEdit.teacher_rec_letter),
    teacher_cog: Boolean(store.teacherDataEdit.teacher_cog),
    teacher_bc: Boolean(store.teacherDataEdit.teacher_bc),
    teacher_app_letter: Boolean(store.teacherDataEdit.teacher_app_letter),
    teacher_supp_doc: store.teacherDataEdit.teacher_supp_doc,
    teacher_glink: store.teacherDataEdit.teacher_glink,
  };

  const yupSchema = Yup.object({
    teacher_date_reg: Yup.string().required("Required field"),
    teacher_gender: Yup.string().required("Required field"),
    teacher_email: Yup.string()
      .email("Email is invalid")
      .required("Required field"),
    teacher_lname: Yup.string().required("Required field"),
    teacher_fname: Yup.string().required("Required field"),
    teacher_dob: Yup.string().required("Required field"),
    teacher_pob: Yup.string().required("Required field"),
    teacher_phone: Yup.string().required("Required field"),

    teacher_address: Yup.string().required("Required field"),
    teacher_city: Yup.string().required("Required field"),
    teacher_province: Yup.string().required("Required field"),
    teacher_postal: Yup.string().required("Required field"),

    teacher_code: Yup.string().required("Required field"),
    teacher_hours: Yup.string().required("Required field"),
    teacher_start_date: Yup.string().required("Required field"),
    teacher_position: Yup.string().required("Required field"),
  });

  return (
    <>
      <HeaderAdmin />
      <SideNavAdmin menu="teacher" />
      {store.loading && <Spinner />}
      <div className="main-content main-top">
        {store.success && <SuccessNotif msg="updated" />}
        {store.error && <ModalError />}
        <div className="teacher-add" id="teacher-add">
          <Formik
            initialValues={initVal}
            validationSchema={yupSchema}
            onSubmit={async (values, actions) => {
              // console.log(values);
              dispatchStore(loadSpinner(true));
              dispatchStore(loadSuccess(false));
              // do ajax
              const editData = await fetchData(
                baseUrl + "rest/admin/teacher/update-teacher.php",
                values,
                dispatchStore
              );

              if (typeof editData === "undefined") {
                dispatchStore(loadSpinner(false));
                return;
              }

              console.log(editData);
              // When no records found
              if (!editData.status) {
                console.log(1);
                dispatchStore(loadSpinner(false));
                dispatchStore(loadError(true));
                dispatchStore(loadMessage(editData.message));
                return;
              }
              dispatchStore(loadSpinner(false));
              dispatchStore(loadSuccess(true));
              scrollPageUp();
            }}
          >
            {(props) => {
              // console.log(props.isValid);

              return (
                <Form>
                  {!props.isValid && <RequiredNotif />}
                  <div className="container-fluid content-block">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="title">
                          <strong>TEACHER INFORMATION</strong>
                        </p>
                      </div>
                    </div>
                    {/* Start of Personal Details  */}
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content t-center">
                          <span>Profile Image</span>
                          <div className="profileUp">
                            <img
                              className="upAvatar"
                              id="img_preview"
                              src="../../../../img/default_avtar.jpg"
                              alt="user avatar"
                            />
                            <div className="upload-button">
                              <i className="fa fa-camera"></i>
                              <input
                                name="nameDisplayPicture"
                                className="file-upload"
                                id="displaypicture"
                                type="file"
                                accept="image/jpg, image/jpeg"
                              />
                            </div>
                          </div>
                          <p style={{ fontSize: "10px" }}>
                            * Only JPEG and JPG supported, * Max 3 MB Upload
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <label className="label">Gender</label>
                              <div className="radio-inline mb--20">
                                <div className="radio">
                                  <MyRadio
                                    label="Male"
                                    type="radio"
                                    name="teacher_gender"
                                    id="idMale"
                                    value="male"
                                  />
                                </div>
                                <div className="radio">
                                  <MyRadio
                                    label="Female"
                                    type="radio"
                                    name="teacher_gender"
                                    id="idFemale"
                                    value="female"
                                  />
                                </div>
                                <MyRadioError name="teacher_gender" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content"></div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Date of Registration"
                                  type="date"
                                  name="teacher_date_reg"
                                  id="idDateOfReg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="First Name"
                                  type="text"
                                  name="teacher_fname"
                                  id="idFname"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Middle Name"
                                  type="text"
                                  name="teacher_mname"
                                  id="idLname"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Last Name"
                                  type="text"
                                  name="teacher_lname"
                                  id="idMname"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Date of Birth"
                                  type="date"
                                  name="teacher_dob"
                                  id="idDateOfBirth"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Place of Birth"
                                  type="text"
                                  name="teacher_pob"
                                  id="idPlaceOfBirth"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Email Address"
                                  type="email"
                                  name="teacher_email"
                                  id="idEmailAdd"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Phone"
                                  type="text"
                                  name="teacher_phone"
                                  id="idPhone"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  End of Personal Details  */}

                    <div className="border-bottom-thick mb--30"></div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>TEACHER RESIDENCE</strong>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Current Address"
                              type="text"
                              name="teacher_address"
                              id="idCurrentAdd"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="City"
                              type="text"
                              name="teacher_city"
                              id="idSelectCity"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Province"
                              type="text"
                              name="teacher_province"
                              id="idSelectProvince"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Postal"
                              type="text"
                              name="teacher_postal"
                              id="idPostal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-bottom-thick mb--30"></div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <p>
                            <strong>SCHOOL DETAILS</strong>
                          </p>
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="form-group">
                                <InputText
                                  label="Employee Code"
                                  type="text"
                                  name="teacher_code"
                                  id="idEmpCode"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="form-group">
                                <InputText
                                  label="Working Hours"
                                  type="text"
                                  name="teacher_hours"
                                  id="idWorkHours"
                                />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="form-group">
                                <InputText
                                  label="Employment Start Date"
                                  type="date"
                                  name="teacher_start_date"
                                  id="idEmpStartDate"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <div className="form-group">
                                <InputText
                                  label="Employment End Date"
                                  type="date"
                                  name="teacher_end_date"
                                  id="idEmpEndDate"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <InputText
                            label="Current Position"
                            type="text"
                            name="teacher_position"
                            id="idCurrentPos"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12"> </div>
                    </div>
                  </div>

                  <div className="container-fluid content-block legal-block">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="title">
                          <strong>201 FILE: SUPPORTING DOCUMENTS</strong>
                        </p>
                      </div>
                    </div>

                    {/* <!-- Start of Family Circumstances --> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <p>
                            <strong>GOVERNMENT IDs</strong>
                          </p>
                          <div className="form-group">
                            <InputText
                              label="Social Security Number:"
                              type="text"
                              name="teacher_sss"
                              id="idSss"
                            />
                          </div>
                          <div className="form-group">
                            <InputText
                              label="Phil. Health Number:"
                              type="text"
                              name="teacher_phil_health"
                              id="idPhilhealth"
                            />
                          </div>
                          <div className="form-group">
                            <InputText
                              label="Tax Identification Number:"
                              type="text"
                              name="teacher_tax_id"
                              id="idTax"
                            />
                          </div>
                          <div className="form-group">
                            <InputText
                              label="PAGIBIG Number:"
                              type="text"
                              name="teacher_pagibig"
                              id="idPagibig"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <p>
                            <strong>CREDENTIALS SUBMITTED</strong>
                          </p>
                          <div className="radio-block mb--40">
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Diploma"
                                type="checkbox"
                                name="teacher_diploma"
                                id="idDiploma"
                                checked={props.values.teacher_diploma}
                              />
                              <br />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Transcript of Record"
                                type="checkbox"
                                name="teacher_tor"
                                id="idTranscript"
                                checked={props.values.teacher_tor}
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Recommendation Letter"
                                type="checkbox"
                                name="teacher_rec_letter"
                                id="idRecommendation"
                                checked={props.values.teacher_rec_letter}
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Certificate of Grades"
                                type="checkbox"
                                name="teacher_cog"
                                id="idCertOfgrades"
                                checked={props.values.teacher_cog}
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Birth Certificate"
                                type="checkbox"
                                name="teacher_bc"
                                id="idBirthCert"
                                checked={props.values.teacher_bc}
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Application Letter"
                                type="checkbox"
                                name="teacher_app_letter"
                                id="idApplicationLetter"
                                checked={props.values.teacher_app_letter}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MyTextArea
                              label="Other Legal Supporting Documents"
                              name="teacher_supp_doc"
                              id="idOtherLegalDoc"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            {/* <label className="label" htmlFor="">
                              
                            </label> */}
                            <MyTextArea
                              label={
                                <>
                                  Google Drive Folder Link:{" "}
                                  <a
                                    href={store.teacherDataEdit.teacher_glink}
                                    // href="google.com"
                                    className="btn-green form-control"
                                    id="folder-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fas fa-external-link-alt"></i>
                                  </a>
                                </>
                              }
                              name="teacher_glink"
                              id="idOtherLegalDoc"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn-green form-control right"
                              id="idBtnSave"
                              name="nameBtnSave"
                              // disabled={!props.dirty || !props.isValid}
                            >
                              <i className="fas fa-save"></i> SAVE
                              {/* {props.isSubmitting ? (
                                <>
                                  SAVING{" "}
                                  <i className="fas fa-spinner fa-spin fa-1x"></i>
                                </>
                              ) : (
                                "SAVE"
                              )} */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AdminTeacherEdit;
