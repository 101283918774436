import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { StoreContext } from "../../store/StoreContext";
import ErrorNotif from "../modal/ErrorNotif";
import { InputText } from "../../module/ModuleFormFields";
import { loadError, loadMessage } from "../../store/StoreActions";
import { baseUrl, fetchData, setStorage } from "../../module/functions";

const ForgotPass = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const btnStyle = {
    width: "100%",
    display: "block",
    borderRadius: "50px",
  };

  const logoStyle = {
    height: "auto",
    marginBottom: "20px",
  };

  const initVal = {
    email: "",
  };

  const yupSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Required field"),
  });

  return (
    <>
      <div className="container main-top" id="login">
        <div className="content-block no-mg no-pd">
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-4 bg-white"
              id="login-input"
            >
              <div className="">
                <div className="logo t-center">
                  <img
                    src="img/fca-logo.png"
                    alt="fca logo"
                    className="mb--10"
                  />
                </div>
                <div className="logo t-center" style={logoStyle}>
                  <span>FORGOT PASSWORD</span>
                </div>
                {store.error && <ErrorNotif />}
                <Formik
                  initialValues={initVal}
                  validationSchema={yupSchema}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    // do ajax
                    const login = await fetchData(
                      baseUrl + "rest/users/read-user-email.php",
                      values,
                      dispatchStore
                    );
                    console.log(login);
                    // When no records found
                    if (!login.status) {
                      console.log("error");
                      dispatchStore(loadError(true));
                      dispatchStore(loadMessage(login.message));
                      return;
                    }

                    // reset form upon successs
                    resetForm();

                    // redirect to  verify page
                    setStorage();
                    window.location.replace("/account-recovery");
                  }}
                >
                  {(props) => {
                    // console.log(props.isSubmitting);

                    return (
                      <Form
                        name="nameFormRequest"
                        id="idFormRequest"
                        className=""
                      >
                        <p>
                          Enter your registered email address and we'll send
                          there a link to reset your password.
                        </p>
                        <div className="icon-container mb--30 t-center">
                          <InputText
                            type="email"
                            placeholder="Registered Email"
                            id="idEmail"
                            name="email"
                            disabled={props.isSubmitting}
                          />
                          <i className="fas fa-envelope"></i>
                        </div>
                        <button
                          type="submit"
                          id="idBtnRequest"
                          className="btn-green form-control t-center mb--20"
                          style={btnStyle}
                          disabled={
                            !props.dirty || !props.isValid || props.isSubmitting
                          }
                        >
                          SEND REQUEST{" "}
                          {props.isSubmitting && (
                            <i className="fas fa-spinner fa-spin fa-1x"></i>
                          )}
                        </button>
                        <a
                          href="/login"
                          id="idBackABtn"
                          name="nameBackBtn"
                          className="color--lightblue t-center"
                          style={btnStyle}
                        >
                          Go back to login
                        </a>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPass;
