import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
// import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import { InputText } from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
// import { loadDocs } from "../../module/functions";
import { loadInfo, setPageNum } from "../../store/StoreActions";
import { scrollPageUp } from "../../module/functions";
import RequiredNotif from "../modal/RequiredNotif";
import ButtonNext from "./ButtonNext";
// import { setPageNum } from "../../store/StoreActions";

const EnrollmentDocsForm = ({ props }) => {
  const { store, dispatchStore } = React.useContext(StoreContext);

  const initVal = {
    student_name: store.student_name,
    decname: store.decname,
  };

  const yupSchema = Yup.object({
    student_name: Yup.string().required("Required field"),
    decname: Yup.string().required("Required field"),
  });

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        // // reset store context
        // dispatchStore(loadError(false));
        // // form data to insert
        // const data = {
        //   ...store.studentInfo,
        //   ...store.residence,
        //   ...store.schoolHistory,
        //   ...store.medicalInfo,
        //   ...store.parentInfoFirst,
        //   ...store.parentInfoSecond,
        //   ...store.emergencyContact,
        //   ...store.financialInfo,
        //   ...values,
        // };
        // console.log(data);
        // // do ajax
        // const enroll = await postData(
        //   baseUrl + "rest/admin/student/create-student.php",
        //   data,
        //   dispatchStore
        // );
        // console.log(enroll);
        // // API Error ocurred
        // if (!enroll.data.status) {
        //   dispatchStore(loadError(true));
        //   dispatchStore(loadMessage(enroll.data.message));
        //   return;
        // }
        // // reset form
        // resetForm();
        // // redirect to signup verify page
        // setStorage();
        // window.location.replace("/thank-you");
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {!store.toggleStudNo ? (
                  <div className="content t-left mb--40">
                    <h1 className="t-bold mb--40 t-center">
                      You are almost done with your online enrollment.
                    </h1>
                    <p>
                      To complete your online registration, you need to send a
                      scanned copy of the following documents to{" "}
                      <span className="t-bold color--lightblue">
                        {" "}
                        info@fca.edu.ph{" "}
                      </span>{" "}
                      or to our{" "}
                      <span className="t-bold color--lightblue">
                        {" "}
                        <a
                          href="https://www.facebook.com/frontlinechristianacademy/"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <i className="fab fa-facebook-messenger"></i> Facebook
                          Messenger{" "}
                        </a>{" "}
                      </span>{" "}
                      after clicking the{" "}
                      <span className="t-bold color--lightblue"> SUBMIT </span>{" "}
                      button for legal verification of student record.
                    </p>

                    <p className="t-bold">
                      Required Documents for New Student/Transferee:
                    </p>
                    <ol className="list-text">
                      <li>Form 138 (Report Card) Original</li>
                      <li>Certificate of Good Moral</li>
                      <li>
                        Certificate of Clearance (Financial and Property
                        Responsibility)
                      </li>
                      <li>Birth Certificate</li>
                      <li>Parent/Guardian Valid Government Issued ID</li>
                    </ol>

                    <p>
                      The school requires that this be a PSA original or
                      "certified true copy" Birth Certificate. If not available,
                      a Local Civil Registry Birth Certificate (Original or
                      Certified True Copy) or Passport (Photocopied at FCA) will
                      be accepted to temporarily be the source of the student's
                      full legal name and age.
                    </p>
                    <p>
                      Once we process and verify your submitted documents and
                      information, we will give you further instruction about
                      your online payment to finalize your enrollment process.{" "}
                    </p>
                    <p>
                      For inquiries, you can reach us at the following contact
                      details below.{" "}
                    </p>
                    <h4 className="t-bold color--lightblue">
                      <a href="https://www.facebook.com/frontlinechristianacademy/">
                        <i className="fab fa-facebook-messenger"></i> Facebook
                        Messenger{" "}
                      </a>
                    </h4>
                    <h4 className="t-bold color--lightblue">
                      <i className="fas fa-envelope"></i> info@fca.edu.ph{" "}
                    </h4>
                    <h4 className="t-bold color--lightblue">
                      <i className="fas fa-mobile"></i> 0908 202 0749 / 0926 363
                      9722{" "}
                    </h4>
                  </div>
                ) : (
                  <div className="content t-left mb--40">
                    <h1 className="t-bold mb--40 t-center">
                      You are almost done with your online enrollment.
                    </h1>
                    <p>
                      Once we process and verify your online registration, we
                      will give you further instruction about your online
                      payment to finalize your enrollment process.{" "}
                    </p>
                    <p>
                      For inquiries, you can reach us at the following contact
                      details below.{" "}
                    </p>
                    <h4 className="t-bold color--lightblue">
                      <a href="https://www.facebook.com/frontlinechristianacademy/">
                        <i className="fab fa-facebook-messenger"></i> Facebook
                        Messenger{" "}
                      </a>
                    </h4>
                    <h4 className="t-bold color--lightblue">
                      <i className="fas fa-envelope"></i>{" "}
                      info@frontlineacademy.asia{" "}
                    </h4>
                    <h4 className="t-bold color--lightblue">
                      <i className="fas fa-mobile"></i> 0908 202 0749 / 0926 363
                      9722{" "}
                    </h4>
                  </div>
                )}

                <hr className="mb--20" />
                {store.gradeIndex >= "10" && (
                  <div className="form-group no-mg">
                    <label className="label t-center">
                      STUDENT CODE OF CONDUCT
                    </label>
                    <p>
                      For students in Grades 7 and above, please expect an email
                      from the FCA guidance office regarding a schedule for a
                      Video Call to discuss the Student Code of Conduct. Thank
                      you.
                    </p>
                  </div>
                )}

                {store.gradeIndex <= "9" && (
                  <div className="form-group no-mg">
                    <label className="label t-center">
                      STUDENT CODE OF CONDUCT
                    </label>
                    <p>
                      Frontline Christian Academy (FCA) strives to set a
                      standard of conduct that will provide a healthy academic
                      environment, and to teach children to live an upright,
                      godly life (Psalm 37:37) by stewarding their relationship
                      with God, others, themselves and creation. Each student is
                      responsible for working towards developing the following
                      characteristics:
                    </p>
                    <ul className="list-text">
                      <li>Cooperation with others</li>
                      <li>Cheerful obedience to all in authority</li>
                      <li>Truthful and honest accountability for behavior</li>
                      <li>
                        Promptness in attendance and completing assignments
                      </li>
                      <li>Diligent and active pursuit of lifelong learning</li>
                      <li>
                        Courtesy and respect for others, such as being quiet in
                        class, raising hand to speak, not interrupting others,
                        not running in the building, and eating with proper
                        manners
                      </li>
                      <li>
                        Cleanliness in person and property: cleanliness in
                        dress, personal hygiene, neat desk, picking up trash,
                        etc.
                      </li>
                      <li>Respect for property: school, classmates and own.</li>
                      <li>
                        Respect for both self and others as individuals
                        possessing inherent dignity as being created in the
                        likeness of our Creator
                      </li>
                      <li>
                        Respect for individuals as loved by God, regardless of
                        their group identities and personal characteristics
                      </li>
                      <li>
                        Morally good conduct in respect to recreation, social
                        relationships and language. (Students must refrain from
                        swearing, use of any type of tobacco, drinking alcoholic
                        beverages if under legal drinking age, sexual activity,
                        pornography, use of illegal drugs, and any abuse of
                        medicinal drugs.)
                      </li>
                    </ul>
                    <p className="no-mg">
                      By typing the student name below, I hereby agree and
                      follow the code of conduct stated above.
                    </p>
                  </div>
                )}

                <div className="form-group mb--40">
                  <label className="label t-center">
                    Student Name <span className="required">*</span>
                  </label>
                  <InputText
                    type="text"
                    id="idSname"
                    name="student_name"
                    placeholder="Your complete name"
                    style={{ textAlign: "center" }}
                  />
                </div>

                <div className="form-group no-mg">
                  <label className="label t-center">
                    DECLARATION BY PARENT OR GUARDIAN
                  </label>
                  <p className="t-bold">I hereby declare that:</p>
                  <ol className="list-text">
                    <li>
                      I have given all necessary and relevant information
                      regarding my child to the school management, and filled
                      out the appropriate forms with valid, true, accurate
                      information, especially with regards to my child's health
                      and dietary management concerns.
                    </li>
                    <li>
                      I understand that the school will do its best to keep my
                      child safe while on the school premises, and will not
                      allow my child to be released to any unauthorized
                      person(s).
                    </li>
                    <li>
                      I understand that when my child is released safely from
                      school, the school no longer holds any civil or legal
                      responsibility for my child, and shall remain free from
                      any and all claims/charges of liability.
                    </li>
                    <li>
                      I understand that my child, if four (4) years old and
                      above, is included in the school's Group Accident
                      Insurance Plan, with accidental medical reimbursement up
                      to a certain amount. I agree to cover the costs of any
                      medical eventualities that exceed the limited of this
                      coverage.
                    </li>
                    <li>
                      I hereby agree to waive any responsibility on the part of
                      Frontline Christian Academy, its trustees, officers,
                      faculty, staff, and any agents of any kind thereof in
                      relation to any loss, damage, death, injury, or accident
                      that may happen to my child/children, unless such loss,
                      damage, injury, accident, or death resulted from the fault
                      or gross negligence of Frontline Christian Academy. I
                      render Frontline Christian Academy and all of the
                      afore-mentioned free and harmless from any liability,
                      suit, or claim filed due to any untoward incident that may
                      occur that is beyond the control of the persons in charge.
                    </li>
                    <li>
                      I understand in the event of any medical emergency, in
                      which after several attempts to contact the legal
                      gaurdians have been unsuccessful, I/we confer upon the
                      school administration and the attending physicians
                      involved the authority to make medical decisions in my/our
                      stead regardless of the results or consequences of any
                      such decisions and attendant actions taken.{" "}
                    </li>
                    <li>
                      I will respect and uphold the values, vision, and mission
                      of FCA to the best of my ability.
                    </li>
                    <li>
                      I will encourage and support my child / children in
                      upholding the FCA student code of conduct and the Student
                      Handbook.
                    </li>
                    <li>
                      I will pay the agreed-upon school fees on or before
                      declared due dates. I understand that failing to do so
                      will result in a penalty of 2% per day on the entire
                      overdue amount, beginning on the day of the due date and
                      ending on the last day of exams.{" "}
                    </li>
                    <li>
                      I will strive my best to foster and maintain a conducive
                      learning environment in the home, and provide any
                      assistance that my child needs for his/her school work.
                    </li>
                    <li>
                      I will do my best to maintain open, honest, appropriate
                      and professional communication with the school, and direct
                      questions and concerns towards the appropriate parties
                      concerned.
                    </li>
                  </ol>
                  <p className="no-mg">
                    By typing the parent/guardian name below, I hereby agree
                    with the declaration stated above.
                  </p>
                </div>

                <div className="form-group mb--40">
                  <label className="label t-center">
                    Parent/Guardian Name <span className="required">*</span>
                  </label>
                  <InputText
                    type="text"
                    id="idFname"
                    name="decname"
                    placeholder="Your complete name"
                    style={{ textAlign: "center" }}
                  />
                </div>

                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                  {/* {!props.isSubmitting && <ButtonBack props={props} />}
                  <button
                    type="submit"
                    className="btn-green form-control right btn-next"
                    id="view-parent"
                    disabled={props.isSubmitting}
                  >
                    SUBMIT{" "}
                    {props.isSubmitting && (
                      <i className="fas fa-spinner fa-spin fa-1x"></i>
                    )}
                  </button> */}
                </div>
                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentDocsForm;
