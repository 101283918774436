import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import HeaderAdmin from "../../HeaderAdmin";
import SideNavAdmin from "../../SideNavAdmin";
import {
  InputText,
  MySelect,
  MyRadio,
  MyRadioError,
  MyTextArea,
  MyCheckbox,
} from "../../../module/ModuleFormFields";
import { StoreContext } from "../../../store/StoreContext";
import {
  baseUrl,
  postDataStudent,
  scrollPageUp,
} from "../../../module/functions";
import Spinner from "../../modal/Spinner";
import SuccessNotif from "../../modal/SuccessNotif";
import RequiredNotif from "../../modal/RequiredNotif";
import ModalError from "../../modal/ModalError";

const AdminStudentEdit = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const [reside, setReside] = React.useState(
    store.studentDataEdit.student_parentf_reside
  );
  const [resideSec, setResideSec] = React.useState(
    store.studentDataEdit.student_parents_reside
  );
  React.useEffect(() => {
    if (store.studentDataEdit.length === 0) {
      props.history.push("/admin/student");
      return;
    }
  }, [store.studentDataEdit.length, props.history]);

  const handlePfResideYes = (props) => {
    setReside("yes");
    props.values.pfaddress = "";
    props.values.pfcity = "";
    props.values.pfprovince = "";
    props.values.pfpostal = "";
  };

  const handlePfResideNo = () => {
    setReside("no");
  };

  const handlePsResideYes = (props) => {
    setResideSec("yes");
    props.values.psaddress = "";
    props.values.pscity = "";
    props.values.psprovince = "";
    props.values.pspostal = "";
  };

  const handlePsResideNo = () => {
    setResideSec("no");
  };

  const initVal = {
    student_id: store.studentDataEdit.student_id,
    student_enroll_status: store.studentDataEdit.student_enroll_status,
    student_date_reg:
      store.studentDataEdit.length === 0
        ? ""
        : store.studentDataEdit.student_date_reg.slice(0, 10),
    student_sy: store.studentDataEdit.student_sy,
    student_grade: store.studentDataEdit.student_grade,
    student_id_num: store.studentDataEdit.student_id_num,
    student_lrn: store.studentDataEdit.student_lrn,
    student_gender: store.studentDataEdit.student_gender,
    student_fn: store.studentDataEdit.student_fn,
    student_mn: store.studentDataEdit.student_mn,
    student_ln: store.studentDataEdit.student_ln,
    student_dob: store.studentDataEdit.student_dob,
    student_pob: store.studentDataEdit.student_pob,
    student_email: store.studentDataEdit.student_email,
    student_phone: store.studentDataEdit.student_phone,
    student_photo: store.studentDataEdit.student_photo,

    student_address: store.studentDataEdit.student_address,
    student_city: store.studentDataEdit.student_city,
    student_province: store.studentDataEdit.student_province,
    student_postal: store.studentDataEdit.student_postal,
    student_school_name: store.studentDataEdit.student_school_name,
    student_school_address: store.studentDataEdit.student_school_address,
    student_school_gpa: store.studentDataEdit.student_school_gpa,
    student_school_disciplinary:
      store.studentDataEdit.student_school_disciplinary,

    student_med_notes: store.studentDataEdit.student_med_notes,
    student_med_doc: store.studentDataEdit.student_med_doc,
    student_med_contact: store.studentDataEdit.student_med_contact,
    student_med_circum: store.studentDataEdit.student_med_circum,

    student_parentf_rel: store.studentDataEdit.student_parentf_rel,
    student_parentf_title: store.studentDataEdit.student_parentf_title,
    student_parentf_ln: store.studentDataEdit.student_parentf_ln,
    student_parentf_fn: store.studentDataEdit.student_parentf_fn,
    pfaddress: store.studentDataEdit.student_parentf_address,
    pfcity: store.studentDataEdit.student_parentf_city,
    pfprovince: store.studentDataEdit.student_parentf_province,
    pfpostal: store.studentDataEdit.student_parentf_postal,
    student_parentf_reside: store.studentDataEdit.student_parentf_reside,
    student_parentf_phone: store.studentDataEdit.student_parentf_phone,
    student_parentf_mobile: store.studentDataEdit.student_parentf_mobile,
    student_parentf_dob: store.studentDataEdit.student_parentf_dob,
    student_parentf_email: store.studentDataEdit.student_parentf_email,
    student_parentf_religion: store.studentDataEdit.student_parentf_religion,
    student_parentf_work: store.studentDataEdit.student_parentf_work,

    student_parents_rel: store.studentDataEdit.student_parents_rel,
    student_parents_title: store.studentDataEdit.student_parents_title,
    student_parents_ln: store.studentDataEdit.student_parents_ln,
    student_parents_fn: store.studentDataEdit.student_parents_fn,
    psaddress: store.studentDataEdit.student_parents_address,
    pscity: store.studentDataEdit.student_parents_city,
    psprovince: store.studentDataEdit.student_parents_province,
    pspostal: store.studentDataEdit.student_parents_postal,
    student_parents_reside: store.studentDataEdit.student_parents_reside,
    student_parents_phone: store.studentDataEdit.student_parents_phone,
    student_parents_mobile: store.studentDataEdit.student_parents_mobile,
    student_parents_dob: store.studentDataEdit.student_parents_dob,
    student_parents_email: store.studentDataEdit.student_parents_email,
    student_parents_religion: store.studentDataEdit.student_parents_religion,
    student_parents_work: store.studentDataEdit.student_parents_work,

    student_emer_primary: store.studentDataEdit.student_emer_primary,
    student_emer_tel: store.studentDataEdit.student_emer_tel,
    student_emer_phone: store.studentDataEdit.student_emer_phone,
    student_emer_secondary: store.studentDataEdit.student_emer_secondary,
    student_emer_tel2: store.studentDataEdit.student_emer_tel2,
    student_emer_phone2: store.studentDataEdit.student_emer_phone2,

    student_fin_father: store.studentDataEdit.student_fin_father,
    student_fin_mother: store.studentDataEdit.student_fin_mother,
    student_fin_who: store.studentDataEdit.student_fin_who,
    student_fin_scheme: store.studentDataEdit.student_fin_scheme,
    fiother: store.studentDataEdit.student_fin_other,

    student_legal_psa: Boolean(store.studentDataEdit.student_legal_psa),
    student_legal_psa_type: store.studentDataEdit.student_legal_psa_type,
    student_legal_lcr: Boolean(store.studentDataEdit.student_legal_lcr),
    student_legal_passport: Boolean(
      store.studentDataEdit.student_legal_passport
    ),
    student_legal_138: Boolean(store.studentDataEdit.student_legal_138),
    student_legal_moral: Boolean(store.studentDataEdit.student_legal_moral),
    // student_legal_clear: Boolean("1"),
    student_legal_clear: Boolean(store.studentDataEdit.student_legal_clear),
    student_legal_support: store.studentDataEdit.student_legal_support,
    student_legal_link: store.studentDataEdit.student_legal_link,
  };

  const yupSchema = Yup.object({
    student_date_reg: Yup.string().required("Required field"),
    // student_sy: Yup.string().required("Required field"),
    // student_grade: Yup.string().required("Required field"),
    // student_id_num: Yup.string().required("Required field"),
    student_lrn: Yup.string().required("Required field"),
    student_gender: Yup.string().required("Required field"),
    student_fn: Yup.string().required("Required field"),
    student_mn: Yup.string().required("Required field"),
    student_ln: Yup.string().required("Required field"),
    student_dob: Yup.string().required("Required field"),
    student_pob: Yup.string().required("Required field"),
    student_email: Yup.string()
      .email("Email is invalid")
      .required("Required field"),
    student_phone: Yup.string().required("Required field"),

    student_address: Yup.string().required("Required field"),
    student_city: Yup.string().required("Required field"),
    student_province: Yup.string().required("Required field"),
    student_postal: Yup.string().required("Required field"),
    student_school_name: Yup.string().required("Required field"),
    student_school_address: Yup.string().required("Required field"),
    // student_school_gpa: Yup.string().required("Required field"),
    student_school_disciplinary: Yup.string().required("Required field"),

    student_med_notes: Yup.string().required("Required field"),
    student_med_doc: Yup.string().required("Required field"),
    // student_med_contact: Yup.string().required("Required field"),
    // student_med_circum: Yup.string().required("Required field"),

    student_parentf_rel: Yup.string().required("Required field"),
    student_parentf_ln: Yup.string().required("Required field"),
    student_parentf_fn: Yup.string().required("Required field"),
    student_parentf_reside: Yup.string().required("Required field"),

    pfaddress: reside === "no" ? Yup.string().required("Required field") : null,
    pfcity: reside === "no" ? Yup.string().required("Required field") : null,
    pfprovince:
      reside === "no" ? Yup.string().required("Required field") : null,
    pfpostal: reside === "no" ? Yup.string().required("Required field") : null,

    student_parentf_mobile: Yup.string().required("Required field"),
    student_parentf_dob: Yup.string().required("Required field"),
    student_parentf_email: Yup.string().required("Required field"),
    student_parentf_religion: Yup.string().required("Required field"),
    student_parentf_work: Yup.string().required("Required field"),

    student_parents_rel: Yup.string().required("Required field"),
    student_parents_ln: Yup.string().required("Required field"),
    student_parents_fn: Yup.string().required("Required field"),
    student_parents_reside: Yup.string().required("Required field"),

    psaddress:
      resideSec === "no" ? Yup.string().required("Required field") : null,
    pscity: resideSec === "no" ? Yup.string().required("Required field") : null,
    psprovince:
      resideSec === "no" ? Yup.string().required("Required field") : null,
    pspostal:
      resideSec === "no" ? Yup.string().required("Required field") : null,

    student_parents_mobile: Yup.string().required("Required field"),
    student_parents_dob: Yup.string().required("Required field"),
    student_parents_email: Yup.string().required("Required field"),
    student_parents_religion: Yup.string().required("Required field"),
    student_parents_work: Yup.string().required("Required field"),

    student_emer_primary: Yup.string().required("Required field"),
    // student_emer_tel: Yup.string().required("Required field"),
    student_emer_phone: Yup.string().required("Required field"),
    // student_emer_secondary: Yup.string().required("Required field"),
    // student_emer_tel2: Yup.string().required("Required field"),
    // student_emer_phone2: Yup.string().required("Required field"),

    student_fin_father: Yup.string().required("Required field"),
    student_fin_mother: Yup.string().required("Required field"),
    student_fin_who: Yup.string().required("Required field"),
    student_fin_scheme: Yup.string().required("Required field"),
  });

  return (
    <>
      <HeaderAdmin />
      <SideNavAdmin menu="student" />
      {store.loading && <Spinner />}
      <div className="main-content main-top">
        {store.success && <SuccessNotif msg="updated" />}
        {store.error && <ModalError />}
        <div className="student-add" id="student-add">
          <Formik
            initialValues={initVal}
            validationSchema={yupSchema}
            onSubmit={async (values, actions) => {
              postDataStudent(
                baseUrl + "rest/admin/student/update-student.php",
                values,
                dispatchStore,
                actions
              );
              scrollPageUp();
            }}
          >
            {(props) => {
              // console.log(props.isValid);

              return (
                <Form>
                  {!props.isValid && <RequiredNotif />}
                  <div className="container-fluid content-block">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="title">
                          <strong>STUDENT INFORMATION</strong>
                        </p>
                      </div>
                    </div>
                    {/* Start of Personal Details  */}
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content t-center">
                          <span>Profile Image</span>
                          <div className="profileUp">
                            <img
                              className="upAvatar"
                              id="img_preview"
                              src="../../../../img/default_avtar.jpg"
                              alt="user avatar"
                            />
                            <div className="upload-button">
                              <i className="fa fa-camera"></i>
                              <input
                                name="nameDisplayPicture"
                                className="file-upload"
                                id="displaypicture"
                                type="file"
                                accept="image/jpg, image/jpeg"
                              />
                            </div>
                          </div>
                          <p style={{ fontSize: "10px" }}>
                            * Only JPEG and JPG supported, * Max 3 MB Upload
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <MySelect
                                  label="Enrollment Status"
                                  name="student_enroll_status"
                                >
                                  <option value="Temporary">Temporary</option>
                                  <option value="Official">Official</option>
                                </MySelect>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Date of Registration"
                                  type="date"
                                  placeholder="Your Password"
                                  name="student_date_reg"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <MySelect label="School Year" name="student_sy">
                                  <option value="2020-2021">2020-2021</option>
                                  <option value="2019-2020">2019-2020</option>
                                  <option value="2018-2019">2018-2019</option>
                                  <option value="2017-2018">2017-2018</option>
                                </MySelect>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <MySelect
                                  id="idSelectStudentGradeLevel"
                                  label="Grade Level"
                                  name="student_grade"
                                >
                                  <option value="nursery">Nursery</option>
                                  <option value="prekinder">Pre-Kinder</option>
                                  <option value="kinder 2">Kinder 2</option>
                                  <option value="grade 1">Grade 1</option>
                                  <option value="grade 2">Grade 2</option>
                                  <option value="grade 3">Grade 3</option>
                                  <option value="grade 4">Grade 4</option>
                                  <option value="grade 5">Grade 5</option>
                                  <option value="grade 6">Grade 6</option>
                                  <option value="grade 7">Grade 7</option>
                                  <option value="grade 8">Grade 8</option>
                                  <option value="grade 9">Grade 9</option>
                                  <option value="grade 10">Grade 10</option>
                                  <option value="grade 11">Grade 11</option>
                                  <option value="grade 12">Grade 12</option>
                                </MySelect>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Student No:"
                                  type="text"
                                  name="student_id_num"
                                  id="student-number"
                                  disabled={true}
                                  placeholder="Optional"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content">
                              <div className="form-group">
                                <InputText
                                  label="Learner Reference No:"
                                  type="text"
                                  name="student_lrn"
                                  id="idLearnerRefNo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  End of Personal Details  */}
                    {/*  Start of Full Name  */}
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <label className="label">Gender</label>
                          <div className="radio-inline mb--20">
                            <div className="radio">
                              <MyRadio
                                label="Male"
                                type="radio"
                                name="student_gender"
                                id="idMale"
                                value="male"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Female"
                                type="radio"
                                name="student_gender"
                                id="idFemale"
                                value="female"
                              />
                            </div>
                            <MyRadioError name="student_gender" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="First Name"
                              type="text"
                              name="student_fn"
                              id="idFnamev"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Middle Name"
                              type="text"
                              name="student_mn"
                              id="idMname"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Last Name"
                              type="text"
                              name="student_ln"
                              id="idLname"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Full Name */}
                    {/* Start of Date of Birth */}
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Date of Birth"
                              type="date"
                              name="student_dob"
                              id="idDateBirth"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Place of Birth"
                              type="text"
                              name="student_pob"
                              id="idPlaceBirth"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Email Address"
                              type="email"
                              name="student_email"
                              id="idEmailAdd"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Phone"
                              type="text"
                              name="student_phone"
                              id="idPhone"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End of Date of Birth */}
                    <div className="border-bottom-thick mb--30"></div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>STUDENT RESIDENCE</strong>
                        </p>
                      </div>
                    </div>
                    {/* <!-- Student Residence --> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Current Address"
                              type="text"
                              id="idCurrentAdd"
                              name="student_address"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="City"
                              type="text"
                              id="idSelectCity"
                              name="student_city"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Province"
                              type="text"
                              id="idSelectProvince"
                              name="student_province"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Postal"
                              type="text"
                              id="idPostal"
                              name="student_postal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End of Address --> */}
                    <div className="border-bottom-thick mb--30"></div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>SCHOOL HISTORY</strong>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Name of Last School Attended"
                              type="text"
                              id="idSchoolAttended"
                              name="student_school_name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="School Address"
                              type="text"
                              id="idSchoolAdd"
                              name="student_school_address"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="GPA last school year"
                              type="text"
                              id="idGpa"
                              name="student_school_gpa"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MyTextArea
                              label="Was the student ever submitted to any form of
                              disciplinary action? If so, why?"
                              id="idDisciplinaryAct"
                              name="student_school_disciplinary"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* 	<!-- End of School History --> */}
                    <div className="border-bottom-thick mb--30"></div>
                    {/* <!-- Start of Medical Information --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>MEDICAL INFORMATION</strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MyTextArea
                              label={
                                <>
                                  Are there any serious medical conditions about
                                  which you wish the school to be aware? Please
                                  indicate below: <br /> <br /> Medical Notes
                                </>
                              }
                              id="idMedicalNotes"
                              name="student_med_notes"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Pediatrician/Family Doctor"
                              type="text"
                              id="idFamilyDoc"
                              name="student_med_doc"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Contact Number"
                              type="text"
                              id="idContactNoDoc"
                              name="student_med_contact"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End of Medical Information -->	 */}

                    {/* <!-- Start of Family Circumstances --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>FAMILY CIRCUMSTANCES</strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MyTextArea
                              label="Are there any family circumstances about which you
                              wish the school to be aware?"
                              id="idCircumstances"
                              name="student_med_circum"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End of Family Circumstances --> */}
                  </div>
                  {/* <!-- End of Student Information --> */}

                  <div className="container-fluid content-block parent-block">
                    {/* <!-- Start of Parent/Guardian Information --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="title">
                          <strong>PARENT OR GUARDIAN INFORMATION</strong>
                        </p>
                      </div>
                    </div>
                    {/* <!-- Start of Family Circumstances --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>FIRST PARENT/GUARDIAN</strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <label className="label">
                            Relationship to Student: (please select one)
                          </label>
                          <div className="radio-inline mb--20">
                            <div className="radio">
                              <MyRadio
                                label="Biological or adoptive mother"
                                type="radio"
                                name="student_parentf_rel"
                                id="idFirstParentBiological"
                                value="biological-1"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Step-mother"
                                type="radio"
                                name="student_parentf_rel"
                                id="idFirstParentStepMother"
                                value="stepmother-1"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Other"
                                type="radio"
                                name="student_parentf_rel"
                                id="idFirstParentOther"
                                value="other-1"
                              />
                            </div>
                            <MyRadioError name="student_parentf_rel" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MySelect
                              label="Title"
                              id="idFirstParentTitle"
                              name="student_parentf_title"
                            >
                              <option value=""> -- </option>
                              <option value="mr.">Mr.</option>
                              <option value="mrs.">Mrs.</option>
                              <option value="ms.">Ms.</option>
                              <option value="dr.">Dr.</option>
                              <option value="atty.">Atty.</option>
                              <option value="prof.">Prof.</option>
                              <option value="bro.">Bro.</option>
                              <option value="sis.">Sis.</option>
                            </MySelect>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Last Name"
                              type="text"
                              id="idFirstParentLastname"
                              name="student_parentf_ln"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="First Name"
                              type="text"
                              id="idFirstParentFirstname"
                              name="student_parentf_fn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <label className="label">
                              Does the student reside with this individual?
                            </label>
                            <div className="radio-inline mb--20">
                              <div className="radio">
                                <MyRadio
                                  label="Yes"
                                  type="radio"
                                  value="yes"
                                  id="idFirstParentResideWithYes"
                                  name="student_parentf_reside"
                                  onClick={() => handlePfResideYes(props)}
                                />
                              </div>
                              <div className="radio">
                                <MyRadio
                                  label="No"
                                  type="radio"
                                  value="no"
                                  id="idFirstParentResideWithNo"
                                  name="student_parentf_reside"
                                  onClick={handlePfResideNo}
                                />
                              </div>
                              <MyRadioError name="student_parentf_reside" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- First Guardian Residence --> */}
                    {reside === "no" && (
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="Address (if different from student's):"
                                type="text"
                                id="idFirstParentAddress"
                                name="pfaddress"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="City"
                                type="text"
                                id="idFirstParentCity"
                                name="pfcity"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="Province"
                                type="text"
                                id="idFirstParentProvince"
                                name="pfprovince"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="Postal"
                                type="text"
                                id="idFirstParentPostal"
                                name="pfpostal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- End of First Address --> */}
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Home Phone No."
                              type="text"
                              id="idFirstParentPhone"
                              name="student_parentf_phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Mobile Phone No."
                              type="text"
                              id="idFirstParentMobile"
                              name="student_parentf_mobile"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Birth Date"
                              type="date"
                              id="idFirstParentBirthday"
                              name="student_parentf_dob"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Email"
                              type="text"
                              id="idFirstParentEmail"
                              name="student_parentf_email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Religion"
                              type="text"
                              id="idFirstParentReligion"
                              name="student_parentf_religion"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Occupation"
                              type="text"
                              id="idFirstParentOccupation"
                              name="student_parentf_work"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom-thick mb--30"></div>
                    {/* <!-- Start of Family Circumstances --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>SECOND PARENT/GUARDIAN</strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <label className="label">
                            Relationship to Student: (please select one)
                          </label>
                          <div className="radio-inline mb--20">
                            <div className="radio">
                              <MyRadio
                                label="Biological or adoptive Father"
                                type="radio"
                                name="student_parents_rel"
                                id="idSecondParentBiological"
                                value="biological-2"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Step-father"
                                type="radio"
                                name="student_parents_rel"
                                id="idSecondParentStepFather"
                                value="stepfather"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Other"
                                type="radio"
                                name="student_parents_rel"
                                id="idSecondParentOther"
                                value="other-2"
                              />
                            </div>
                            <MyRadioError name="student_parents_rel" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MySelect
                              label="Title"
                              id="idSecondParentTitle"
                              name="student_parents_title"
                            >
                              <option value=""> -- </option>
                              <option value="mr.">Mr.</option>
                              <option value="mrs.">Mrs.</option>
                              <option value="ms.">Ms.</option>
                              <option value="dr.">Dr.</option>
                              <option value="atty.">Atty.</option>
                              <option value="prof.">Prof.</option>
                              <option value="bro.">Bro.</option>
                              <option value="sis.">Sis.</option>
                            </MySelect>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Last Name"
                              type="text"
                              id="idSecondParentLastname"
                              name="student_parents_ln"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="First Name"
                              type="text"
                              id="idSecondParentFirstname"
                              name="student_parents_fn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <label className="label">
                              Does the student reside with this individual?
                            </label>
                            <div className="radio-inline mb--20">
                              <div className="radio">
                                <MyRadio
                                  label="Yes"
                                  type="radio"
                                  value="yes"
                                  id="idSecondParentResideWithYes"
                                  name="student_parents_reside"
                                  onClick={() => handlePsResideYes(props)}
                                />
                              </div>
                              <div className="radio">
                                <MyRadio
                                  label="No"
                                  type="radio"
                                  value="no"
                                  id="idSecondParentResideWithNo"
                                  name="student_parents_reside"
                                  onClick={handlePsResideNo}
                                />
                              </div>
                              <MyRadioError name="student_parents_reside" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Second Guardian Residence --> */}
                    {resideSec === "no" && (
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="Address (if different from student's):"
                                type="text"
                                id="idSecondParentAddress"
                                name="psaddress"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="City"
                                type="text"
                                id="idSecondParentCity"
                                name="pscity"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="Province"
                                type="text"
                                id="idSecondParentProvince"
                                name="psprovince"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="content">
                            <div className="form-group">
                              <InputText
                                label="Postal"
                                type="text"
                                id="idSecondParentPostal"
                                name="pspostal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <!-- End of Second Address --> */}
                    <div className="row">
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Home Phone No."
                              type="text"
                              id="idSecondParentPhone"
                              name="student_parents_phone"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Mobile Phone No."
                              type="text"
                              id="idSecondParentMobile"
                              name="student_parents_mobile"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Birth Date"
                              type="date"
                              id="idSecondParentBirthday"
                              name="student_parents_dob"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Email"
                              type="text"
                              id="idSecondParentEmail"
                              name="student_parents_email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Religion"
                              type="text"
                              id="idSecondParentReligion"
                              name="student_parents_religion"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Occupation"
                              type="text"
                              id="idSecondParentOccupation"
                              name="student_parents_work"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-bottom-thick mb--20"></div>
                    {/* <!-- Start of Emergency Contacts --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>EMERGENCY CONTACTS</strong>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Primary Contact Person"
                              type="text"
                              id="idPrimaryPerson"
                              name="student_emer_primary"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Home Tel. No."
                              type="text"
                              id="idPrimaryTel"
                              name="student_emer_tel"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Other Phone"
                              type="text"
                              id="idPrimaryOtherPhone"
                              name="student_emer_phone"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Secodary Contact Person"
                              type="text"
                              id="idSecondaryPerson"
                              name="student_emer_secondary"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Home Tel. No."
                              type="text"
                              id="idSecondaryTel"
                              name="student_emer_tel2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Other Phone"
                              type="text"
                              id="idSecondaryOtherPhone"
                              name="student_emer_phone2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-bottom-thick mb--30"></div>

                    {/* <!-- Start of Emergency Contacts --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>FINANCIAL INFORMATION</strong>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Father's Gross Monthly Income"
                              type="text"
                              id="idFatherIncome"
                              name="student_fin_father"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Mother's Gross Monthly Income:"
                              type="text"
                              id="idMotherFinancer"
                              name="student_fin_mother"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <InputText
                              label="Who will be financing the child's education?"
                              type="text"
                              id="idParentFinancer"
                              name="student_fin_who"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-bottom-thick mb--30"></div>

                    {/* <!-- Start of Payment Scheme --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          <strong>PAYMENT SCHEME</strong>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="radio-block mb--20">
                            <div className="radio">
                              <MyRadio
                                label="Scheme A"
                                type="radio"
                                id="scheme-a"
                                value="Scheme A"
                                name="student_fin_scheme"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Scheme B"
                                type="radio"
                                id="scheme-b"
                                value="Scheme B"
                                name="student_fin_scheme"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Scheme C"
                                type="radio"
                                id="scheme-c"
                                value="Scheme C"
                                name="student_fin_scheme"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Scheme D"
                                type="radio"
                                id="scheme-d"
                                value="Scheme D"
                                name="student_fin_scheme"
                              />
                            </div>
                            <div className="radio">
                              <MyRadio
                                label="Other (Please specify)"
                                type="radio"
                                id="scheme-o"
                                value="Scheme O"
                                name="student_fin_scheme"
                              />
                            </div>
                            <div className="radio">
                              <InputText
                                type="text"
                                id="idSchemeOther"
                                name="fiother"
                              />
                            </div>
                            <MyRadioError name="student_fin_scheme" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                  </div>

                  <div className="container-fluid content-block legal-block">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="title">
                          <strong>LEGAL VERIFICATION</strong>
                        </p>
                      </div>
                    </div>

                    {/* <!-- Start of Family Circumstances --> */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p>
                          The school requires that this be a PSA original or
                          "certified true copy" Birth Certificate. If not
                          available, a Local Civil Registry Birth Certificate
                          (Original or Certified True Copy) or Passport
                          (Photocopied at FCA) will be accepted to temporarily
                          be the source of the student's full legal name and
                          age.
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <p>
                            <strong>
                              The indicated documents have been provided to the
                              school and copies made:{" "}
                            </strong>
                          </p>
                          <div className="radio-block mb--40">
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="PSA / Philippine Statistics Authority Original
                              Birth Certificate"
                                type="checkbox"
                                id="idPsa"
                                checked={props.values.student_legal_psa}
                                name="student_legal_psa"
                              />
                              <br />
                              <div style={{ marginLeft: "20px" }}>
                                <MyRadio
                                  label="Original"
                                  type="radio"
                                  id="idOriginal"
                                  name="student_legal_psa_type"
                                  value="original"
                                />
                                <MyRadio
                                  label="Photocopy"
                                  type="radio"
                                  id="idPhotocopy"
                                  name="student_legal_psa_type"
                                  value="photocopy"
                                />
                              </div>
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="LCR / Local Civil Registry Birth Certificate
                                (Temporary Enrollment Only)"
                                type="checkbox"
                                id="idLcr"
                                checked={props.values.student_legal_lcr}
                                name="student_legal_lcr"
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Passport / Photocopy made at FCA (Temporary
                                  Enrollment Only)"
                                type="checkbox"
                                id="idPassport"
                                checked={props.values.student_legal_passport}
                                name="student_legal_passport"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <p>
                            <strong>Required School Documents: </strong>
                          </p>
                          <div className="radio-block mb--20">
                            <div className="radio  mb--20">
                              <MyCheckbox
                                label="Form 138 (Report Card) Original"
                                type="checkbox"
                                id="idForm138"
                                checked={props.values.student_legal_138}
                                name="student_legal_138"
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Certificate of Good Moral"
                                type="checkbox"
                                id="idGoodMoral"
                                checked={props.values.student_legal_moral}
                                name="student_legal_moral"
                              />
                            </div>
                            <div className="radio mb--20">
                              <MyCheckbox
                                label="Certificate of Clearance (Financial and Property
                                  Responsibility)"
                                type="checkbox"
                                id="idClearance"
                                checked={props.values.student_legal_clear}
                                // value="certificate"
                                name="student_legal_clear"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MyTextArea
                              label="Other Legal Supporting Documents"
                              id="idOtherLegalDoc"
                              name="student_legal_support"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <MyTextArea
                              label={
                                <>
                                  Google Drive Folder Link:{" "}
                                  <a
                                    href={
                                      store.studentDataEdit.student_legal_link
                                    }
                                    className="btn-green form-control"
                                    id="folder-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fas fa-external-link-alt"></i>
                                  </a>
                                </>
                              }
                              id="google-drive"
                              name="student_legal_link"
                              cols="30"
                              rows="10"
                            ></MyTextArea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="content">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn-green form-control right"
                              id="idBtnSave"
                              name="nameBtnSave"
                              // disabled={!props.dirty || !props.isValid}
                            >
                              <i className="fas fa-save"></i> SAVE
                              {/* {props.isSubmitting ? (
                                <>
                                  SAVING{" "}
                                  <i className="fas fa-spinner fa-spin fa-1x"></i>
                                </>
                              ) : (
                                "SAVE"
                              )} */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AdminStudentEdit;
