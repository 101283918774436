import React from "react";

const AdminStudentGradeFilter = ({ change }) => {
  return (
    <div className="student-filter mb--20">
      <label className="">Select Class Name</label>
      <select
        name="student_grade"
        id="idStudent_grade"
        className="droparrow form-control"
        onChange={change}
      >
        <option value="nursery">Nursery</option>
        <option value="prekinder">Pre-Kinder</option>
        <option value="kinder 2">Kinder 2</option>
        <option value="grade 1">Grade 1</option>
        <option value="grade 2">Grade 2</option>
        <option value="grade 3">Grade 3</option>
        <option value="grade 4">Grade 4</option>
        <option value="grade 5">Grade 5</option>
        <option value="grade 6">Grade 6</option>
        <option value="grade 7">Grade 7</option>
        <option value="grade 8">Grade 8</option>
        <option value="grade 9">Grade 9</option>
        <option value="grade 10">Grade 10</option>
        <option value="grade 11">Grade 11</option>
        <option value="grade 12">Grade 12</option>
      </select>
    </div>
  );
};

export default AdminStudentGradeFilter;
