export const loadSpinner = (val) => {
  return {
    type: "LOADING",
    payload: val,
  };
};

export const loadError = (val) => {
  return {
    type: "ERROR",
    payload: val,
  };
};

export const loadInfo = (val) => {
  return {
    type: "INFO",
    payload: val,
  };
};

export const loadSuccess = (val) => {
  return {
    type: "SUCCESS",
    payload: val,
  };
};

export const loadMessage = (val) => {
  return {
    type: "MESSAGE",
    payload: val,
  };
};

export const setIsDel = (val) => {
  return {
    type: "IS_DEL",
    payload: val,
  };
};

export const setIsDelStudent = (val) => {
  return {
    type: "IS_DEL_STUDENT",
    payload: val,
  };
};

export const setIsDelTeacher = (val) => {
  return {
    type: "IS_DEL_TEACHER",
    payload: val,
  };
};

export const setDelId = (val) => {
  return {
    type: "DEL_ID",
    payload: val,
  };
};

export const setLogout = (val) => {
  return {
    type: "LOGOUT",
    payload: val,
  };
};

export const loadServerError = (val) => {
  return {
    type: "SERVER_ERROR",
    payload: val,
  };
};

export const loadServerErrorMsg = (val) => {
  return {
    type: "SERVER_ERROR_MSG",
    payload: val,
  };
};

export const setUserEmail = (val) => {
  return {
    type: "USER_EMAIL",
    payload: val,
  };
};

export const setPageNum = (val) => {
  return {
    type: "PAGE_NUM",
    payload: val,
  };
};

export const setToggleStudNo = (val) => {
  return {
    type: "TOGGLE_STUD_NO",
    payload: val,
  };
};

export const setpfAddress = (val) => {
  return {
    type: "SAME_PFADDRESS",
    payload: val,
  };
};

export const setpsAddress = (val) => {
  return {
    type: "SAME_PSADDRESS",
    payload: val,
  };
};

export const setFee = (val) => {
  return {
    type: "FEE",
    payload: val,
  };
};

export const setGradeIndex = (val) => {
  return {
    type: "GRADE_INDEX",
    payload: val,
  };
};

export const setStudentData = (val) => {
  return {
    type: "STUDENT_DATA",
    payload: val,
  };
};

export const setStudentDataEdit = (val) => {
  return {
    type: "STUDENT_DATA_EDIT",
    payload: val,
  };
};

export const setTeacherDataEdit = (val) => {
  return {
    type: "TEACHER_DATA_EDIT",
    payload: val,
  };
};

export const setStudentInfo = (
  sclassification,
  ssy,
  slrn,
  sstudentNo,
  sgrade,
  sgender,
  sfirstname,
  smiddlename,
  slastname,
  sdob,
  spob,
  semail,
  sphone,
  sphoto
) => {
  return {
    type: "STUDENT_INFO",
    payload: {
      sclassification,
      ssy,
      slrn,
      sstudentNo,
      sgrade,
      sgender,
      sfirstname,
      smiddlename,
      slastname,
      sdob,
      spob,
      semail,
      sphone,
      sphoto,
    },
  };
};

export const setResidence = (rcurrentAddress, rcity, rprovince, rpostal) => {
  return {
    type: "RESIDENCE",
    payload: { rcurrentAddress, rcity, rprovince, rpostal },
  };
};

export const setSchoolHistory = (
  shschool,
  shschoolAddress,
  shgpa,
  shdisciplinary
) => {
  return {
    type: "SCHOOL_HISTORY",
    payload: { shschool, shschoolAddress, shgpa, shdisciplinary },
  };
};

export const setMedicalInfo = (mnotes, mdoctor, mcontactNo, mcircumstance) => {
  return {
    type: "MEDICAL_INFO",
    payload: { mnotes, mdoctor, mcontactNo, mcircumstance },
  };
};

export const setParentInfoFirst = (
  pfrelation,
  pflastname,
  pffirstname,
  pfaddress,
  pfcity,
  pfprovince,
  pfpostal,
  pfresideWith,
  pfphone,
  pfmobile,
  pfdob,
  pfemail,
  pfreligion,
  pfoccupation
) => {
  return {
    type: "PARENT_FIRST",
    payload: {
      pfrelation,
      pflastname,
      pffirstname,
      pfaddress,
      pfcity,
      pfprovince,
      pfpostal,
      pfresideWith,
      pfphone,
      pfmobile,
      pfdob,
      pfemail,
      pfreligion,
      pfoccupation,
    },
  };
};

export const setParentInfoSecond = (
  psrelation,
  pslastname,
  psfirstname,
  psaddress,
  pscity,
  psprovince,
  pspostal,
  psresideWith,
  psphone,
  psmobile,
  psdob,
  psemail,
  psreligion,
  psoccupation
) => {
  return {
    type: "PARENT_SECOND",
    payload: {
      psrelation,
      pslastname,
      psfirstname,
      psaddress,
      pscity,
      psprovince,
      pspostal,
      psresideWith,
      psphone,
      psmobile,
      psdob,
      psemail,
      psreligion,
      psoccupation,
    },
  };
};

export const setEmergencyContact = (
  emprimary,
  emptel,
  empotherPhone,
  emsecondary,
  emstel,
  emsotherPhone
) => {
  return {
    type: "EMERGENCY_CONTACT",
    payload: {
      emprimary,
      emptel,
      empotherPhone,
      emsecondary,
      emstel,
      emsotherPhone,
    },
  };
};

export const setFinancial = (
  fifatherIncome,
  fiMotherIncome,
  fieduc
  // fischeme,
  // fiother
) => {
  return {
    type: "FINANCIAL",
    payload: {
      fifatherIncome,
      fiMotherIncome,
      fieduc,
      // fischeme,
      // fiother,
    },
  };
};

export const setStudName = (val) => {
  return {
    type: "STUDNAME",
    payload: val,
  };
};

export const setDocs = (val) => {
  return {
    type: "DECNAME",
    payload: val,
  };
};

export const setConsentParentName = (val) => {
  return {
    type: "CONSENT_PARENT_NAME",
    payload: val,
  };
};

export const setConsentStudName = (val) => {
  return {
    type: "CONSENT_STUDENT_NAME",
    payload: val,
  };
};

export const setConsentDecName = (val) => {
  return {
    type: "CONSENT_DEC_NAME",
    payload: val,
  };
};
