import React from "react";
// import { StoreContext } from "../../store/StoreContext";

const WarningNotif = ({ msg }) => {
  // const { serverError } = msg;
  // const { store } = React.useContext(StoreContext);
  return (
    <div className="notify-bar notify-show notify-warning mb--20">
      <i className="fas fa-info-circle notify-element"></i>
      <div className="notify-bar-text notify-element">
        <p className="no-mg">
          Please skip this section if there are no changes in information.
        </p>
      </div>
    </div>
  );
};

export default WarningNotif;
