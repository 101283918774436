import React from "react";
import queryString from "query-string";
import { StoreContext } from "../../store/StoreContext";
import { postData } from "../../ajax/RequestMethod";
import Spinner from "../modal/Spinner";
import { baseUrl } from "../../module/functions";

const SignupVerifyEmail = (props) => {
  const { dispatchStore } = React.useContext(StoreContext);
  const param = queryString.parse(props.location.search);
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const verifyEmail = async () => {
      const key = { key: param.key };
      const verifyData = await postData(
        baseUrl + "rest/users/read-activate-key.php",
        key,
        dispatchStore
      );
      const verifyStatus = verifyData.data.status;
      if (verifyStatus) {
        const activateData = await postData(
          baseUrl + "rest/users/update-user-key-activate.php",
          key,
          dispatchStore
        );
        const activateStatus = activateData.data.status;
        console.log(activateData.data);
        activateStatus && setValid(true);
      }
      setLoading(false);
    };
    verifyEmail();
  }, [dispatchStore, param.key]);

  const imgStyle = { margin: "10px" };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : valid ? (
        <>
          <div className="container" id="login">
            <div className="signup-content">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-3"></div>
                <div className="col-lg-6 col-md-4 col-sm-6 bg-white signup-box">
                  <form
                    id="idFormSignUp"
                    name="nameFormSignUp"
                    className="t-center"
                  >
                    <img src="img/fca-logo.png" alt="" className="mb--20" />
                    <h1 className="t-bold mb--30">Sign Up Successful!</h1>
                    <p>
                      Welcome to FCA School Management System! You can now start
                      using this system. Please, proceed to the login page.
                    </p>
                    <a href="/login" className="btn-square mb--30">
                      PROCEED TO LOGIN
                    </a>
                    <hr style={{ margin: "20px 0" }} />
                    <ul>
                      <li>
                        <a href="https://fca.edu.ph/">
                          <img
                            style={imgStyle}
                            src="https://ems.frontlinebusiness.com.ph/rest/account/mail/images/globe-asia.png"
                            alt="globe icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/frontlinechristianacademy/">
                          <img
                            style={imgStyle}
                            src="https://ems.frontlinebusiness.com.ph/rest/account/mail/images/facebook.png"
                            alt="facebook icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="mailto:patrick.reyes@frontlinebusiness.com.ph?Subject=Verify%20Email">
                          <img
                            style={imgStyle}
                            src="https://ems.frontlinebusiness.com.ph/rest/account/mail/images/envelope.png"
                            alt="envelope icon"
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="footer">
                      <h5 className="title">Frontline Christian Academy</h5>
                      <p>
                        &copy; {new Date().getFullYear()} FCA, ALL RIGHT
                        RESERVED
                      </p>
                      <p>202 Maharlika Highway San Francisco Calihan</p>
                      <p>San Pablo City, Laguna 4000</p>
                    </div>
                  </form>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-3"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>The page you're trying to view has expired or invalid.</p>
      )}
    </>
  );
};

export default SignupVerifyEmail;
