import React from "react";

const PageNotFound = (props) => {
  const pStyle = {
    color: "#3e8d3e",
    margin: "0px",
    fontSize: "90px",
    lineHeight: "1.3em",
    fontWeight: "bold",
  };
  return (
    <div className="container-fluid">
      <div className="dashboard content-block clearfix" id="dashboard-list">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="t-center">
              <p style={pStyle}>404</p>
              <h1 className="mb--30" style={{ fontSize: "30px" }}>
                Oops, You are lost!
              </h1>
              <p
                className="mb--30"
                style={{ fontSize: "16px", lineHeight: "1.3em" }}
              >
                We are very sorry for the inconvenience. it looks like you are
                trying to access a page that either has been deleted or never
                existed.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
