import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StoreProvider } from "./store/StoreContext";
import Signup from "./components/signup/Signup";
import SignupVerify from "./components/signup/SignupVerify";
import SignupVerifyEmail from "./components/signup/SignupVerifyEmail";
import Login from "./components/login/Login";
import ForgotPass from "./components/forgot/ForgotPass";
import CreatePass from "./components/forgot/CreatePass";
import Enrollment from "./components/enrollment/Enrollment";
import ProtectedRoute from "./components/ProtectedRoute";
import EnrollmentThankyou from "./components/enrollment/EnrollmentThankyou";
import AdminStudent from "./components/admin/student/AdminStudent";
import AdminStudentEdit from "./components/admin/student/AdminStudentEdit";
import AdminStudentAdd from "./components/admin/student/AdminStudentAdd";
import NewPasswordSuccess from "./components/forgot/NewPasswordSuccess";
import ForgotPassRecovery from "./components/forgot/ForgotPassRecovery";
import AdminTeacher from "./components/admin/teacher/AdminTeacher";
import AdminTeacherAdd from "./components/admin/teacher/AdminTeacherAdd";
import AdminTeacherEdit from "./components/admin/teacher/AdminTeacherEdit";
import PageNotFound from "./components/PageNotFound";

function App() {
  return (
    <div className="App">
      <StoreProvider>
        <Router basename="/">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/forgot-password" component={ForgotPass} />
            <Route
              exact
              path="/account-recovery"
              component={ForgotPassRecovery}
            />
            <Route exact path="/create-new-password" component={CreatePass} />
            <Route
              exact
              path="/new-password-success"
              component={NewPasswordSuccess}
            />
            <Route exact path="/signup-verification" component={SignupVerify} />
            <Route exact path="/enrollment" component={Enrollment} />
            <Route exact path="/thank-you" component={EnrollmentThankyou} />
            <Route
              exact
              path="/signup-verify-email"
              component={SignupVerifyEmail}
            />
            <ProtectedRoute
              exact
              path="/admin/student"
              component={AdminStudent}
            />
            <ProtectedRoute
              exact
              path="/admin/student/edit"
              component={AdminStudentEdit}
            />
            <ProtectedRoute
              exact
              path="/admin/student/add"
              component={AdminStudentAdd}
            />

            <ProtectedRoute
              exact
              path="/admin/teacher"
              component={AdminTeacher}
            />

            <ProtectedRoute
              exact
              path="/admin/teacher/add"
              component={AdminTeacherAdd}
            />
            <ProtectedRoute
              exact
              path="/admin/teacher/edit"
              component={AdminTeacherEdit}
            />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </StoreProvider>
    </div>
  );
}

export default App;
