import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import { InputText } from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import { loadResidence, scrollPageUp } from "../../module/functions";
import WarningNotif from "../modal/WarningNotif";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentResidenceForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  let yupSchema = "";

  const initVal = {
    rcurrentAddress: store.residence.rcurrentAddress,
    rcity: store.residence.rcity,
    rprovince: store.residence.rprovince,
    rpostal: store.residence.rpostal,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      rcurrentAddress: Yup.string().required("Required field"),
      rcity: Yup.string().required("Required field"),
      rprovince: Yup.string().required("Required field"),
      rpostal: Yup.string().required("Required field"),
    });
  }

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadResidence(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                {store.toggleStudNo && <WarningNotif />}
                <p className="form-title">
                  <strong>STUDENT RESIDENCE</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <InputText
                    label="Current Address"
                    type="text"
                    id="idCurrentAdd"
                    name="rcurrentAddress"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="City"
                    type="text"
                    id="idSelectCity"
                    name="rcity"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Province"
                    type="text"
                    id="idSelectProvince"
                    name="rprovince"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="Postal"
                    type="text"
                    id="idPostal"
                    name="rpostal"
                  />
                </div>

                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>

                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentResidenceForm;
