import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PageNumber from "./PageNumber";
import ButtonNext from "./ButtonNext";
import ButtonBack from "./ButtonBack";
import { InputText, MyTextArea } from "../../module/ModuleFormFields";
import { StoreContext } from "../../store/StoreContext";
import { loadSchoolHistory, scrollPageUp } from "../../module/functions";
import { setPageNum, loadInfo } from "../../store/StoreActions";
import WarningNotif from "../modal/WarningNotif";
import RequiredNotif from "../modal/RequiredNotif";

const EnrollmentSchoolHistoryForm = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  let yupSchema = "";

  const initVal = {
    shschool: store.schoolHistory.shschool,
    shschoolAddress: store.schoolHistory.shschoolAddress,
    shgpa: store.schoolHistory.shgpa,
    shdisciplinary: store.schoolHistory.shdisciplinary,
  };

  if (!store.toggleStudNo) {
    yupSchema = Yup.object({
      shschool: Yup.string().required("Required field"),
      shschoolAddress: Yup.string().required("Required field"),
      // shgpa: Yup.string().required("Required field"),
      // shdisciplinary: Yup.string().required("Required field"),
    });
  }

  return (
    <Formik
      initialValues={initVal}
      validationSchema={yupSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        dispatchStore(loadInfo(false));
        scrollPageUp();
        dispatchStore(setPageNum(store.pageNum + 1));
        loadSchoolHistory(dispatchStore, values);
      }}
    >
      {(props) => {
        // console.log(props.values);

        return (
          <Form id="id-form-student" className="form-content">
            {store.info && !props.isValid && <RequiredNotif />}
            <div className="row">
              <div className="col-lg-12">
                {store.toggleStudNo && <WarningNotif />}
                <p className="form-title">
                  <strong>SCHOOL HISTORY</strong>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <InputText
                    label="Name of Last School Attended"
                    type="text"
                    id="idSchoolAttended"
                    name="shschool"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="School Address"
                    type="text"
                    id="idSchoolAdd"
                    name="shschoolAddress"
                  />
                </div>

                <div className="form-group">
                  <InputText
                    label="GPA last school year"
                    type="text"
                    id="idGpa"
                    name="shgpa"
                    placeholder="Optional"
                  />
                </div>

                <div className="form-group">
                  <MyTextArea
                    label="Was the student ever submitted to any form of disciplinary action? If so, why?"
                    name="shdisciplinary"
                    id="idDisciplinaryAct"
                    cols="30"
                    rows="10"
                    placeholder="If there's any"
                  ></MyTextArea>
                </div>

                <div className="form-groupt-center clearfix">
                  <ButtonBack props={props} />
                  <ButtonNext props={props} />
                </div>

                <div className="form-group t-center">
                  <PageNumber />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EnrollmentSchoolHistoryForm;
