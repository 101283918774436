import React from "react";
// import PageNumber from "./PageNumber";

const HeaderEnrollment = (props) => {
  return (
    <div className="bg-highlight  bg-yellow">
      <div className="row">
        <div className="col-lg-12">
          <div className="content-box t-center color--white">
            <img
              src="../../img/fca-new-white-logo-2022.png"
              alt="fca logo"
              width="400px"
            />
            <h1 className="t-bold">
              ONLINE STUDENT REGISTRATION &amp; ENROLLMENT FORM
            </h1>
            {/* <PageNumber /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderEnrollment;
