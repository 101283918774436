import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { baseUrl, fetchData } from "../module/functions";
import { StoreContext } from "../store/StoreContext";
import Spinner from "./modal/Spinner";
import { loadError } from "../store/StoreActions";
// import LogoLoading from "./modal/LogoLoading";

const ProtectedRoute = ({ component: Component }) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(true);
  const [isAuth, setIsAuth] = React.useState("");

  React.useEffect(() => {
    const isAuth = async () => {
      const data = await fetchData(
        baseUrl + "rest/users/read-token.php",
        { token: localStorage.getItem("token") },
        dispatchStore
      );
      console.log(data);
      if (typeof data === "undefined" || !data.status) {
        dispatchStore(loadError(false));
        setLoading(false);
        setIsAuth("456");
        return;
      } else {
        setIsAuth("123");
        setLoading(false);
        return;
      }
    };
    isAuth();
  }, [dispatchStore, store.info]);

  return loading ? (
    <Spinner />
  ) : isAuth === "123" ? (
    <Route render={(props) => <Component {...props} />} />
  ) : isAuth === "456" ? (
    <Redirect to={{ pathname: "/login" }} />
  ) : null;
};

export default ProtectedRoute;
