import axios from "axios";
import { loadError, loadMessage } from "../store/StoreActions";

export const baseUri = "https://sims.frontlineacademy.asia";
export const uploadDir =
  "https://sims.frontlineacademy.asia/rest/uploads/images/";

export const getData = (url, key = null, dispatchStore) => {
  const data = axios
    .get(url, { params: { key } }, dispatchStore)
    .then((res) => res)
    .catch((error) => {
      // dispatchStore(loadSpinner(false));
      dispatchStore(loadError(true));
      dispatchStore(
        loadMessage(
          "Network error. Make sure you are connected to the internet or you may contact patrick.reyes@frontlinebusiness.com.ph"
        )
      );
      console.log(error);
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error response", error.request);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        console.log("Error request", error.request);
        // NetworkError(dispatchStore);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error else", error.message);
      }
    });
  return data;
};

export const postData = (url, formData, dispatchStore) => {
  const data = axios
    .post(url, formData)
    .then((res) => res)
    .catch((error) => {
      // dispatchStore(loadSpinner(false));
      dispatchStore(loadError(true));
      dispatchStore(
        loadMessage(
          "Network error. Make sure you are connected to the internet or you may contact patrick.reyes@frontlinebusiness.com.ph"
        )
      );
      console.log(error);
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.log("Error response", error.request);
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
        console.log("Error request", error.request);
        // NetworkError(dispatchStore);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error else", error.message);
      }
    });
  return data;
};
