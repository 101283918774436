import React from "react";
import { useField } from "formik";
import { StoreContext } from "../store/StoreContext";
import { scrollPageUp } from "./functions";
import { loadInfo, setGradeIndex } from "../store/StoreActions";

export const InputText = ({ label, ...props }) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);

  React.useEffect(() => {
    // props.name === "fiother"
    //   ? dispatchStore(loadServerError(true))
    //   : dispatchStore(loadServerError(false));
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
  }, [meta.error, meta.touched, dispatchStore]);

  return (
    <>
      <label className="label" htmlFor={props.id || props.name}>
        {label}
      </label>
      {store.error ? (
        <input
          className={
            (meta.touched && meta.error) || store.error
              ? "valid error-show "
              : null
          }
          {...field}
          {...props}
        />
      ) : (
        <>
          {/* <>{meta.touched && meta.error && scrollPageUp()}</> */}
          <input
            className={meta.touched && meta.error ? "valid error-show" : null}
            {...field}
            {...props}
          />
        </>
      )}

      {props.name !== "fiother" && meta.touched && meta.error ? (
        // store.toggleStudNo ? null : (
        <span className="error-msg error-show">{meta.error}</span>
      ) : // )
      null}
    </>
  );
};

export const InputTextConsent = ({ label, ...props }) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);

  React.useEffect(() => {
    // props.name === "fiother"
    //   ? dispatchStore(loadServerError(true))
    //   : dispatchStore(loadServerError(false));
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
  }, [meta.error, meta.touched, dispatchStore]);

  return (
    <>
      {store.error ? (
        <input
          className={
            (meta.touched && meta.error) || store.error
              ? "valid error-show parentConsentTextField"
              : "parentConsentTextField"
          }
          {...field}
          {...props}
        />
      ) : (
        <>
          {/* <>{meta.touched && meta.error && scrollPageUp()}</> */}
          <input
            className={
              meta.touched && meta.error
                ? "valid error-show parentConsentTextField"
                : "parentConsentTextField"
            }
            {...field}
            {...props}
          />
        </>
      )}
    </>
  );
};

export const MyRadio = ({ label, ...props }) => {
  const { dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
  }, [meta.error, meta.touched, dispatchStore]);

  return (
    <>
      <input
        className={meta.touched && meta.error ? "valid error-show" : null}
        {...field}
        {...props}
      />
      <label htmlFor={props.id || props.name}>{label}</label>

      {/* {meta.touched && meta.error ? (
        // store.toggleStudNo ? null : (
        <span className="error-msg error-show">{meta.error}</span>
      ) : // )
      null} */}
    </>
  );
};

export const MyRadioError = ({ label, ...props }) => {
  const { dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
  }, [meta.error, meta.touched, dispatchStore]);

  return (
    <>
      {meta.touched && meta.error ? (
        <span className="error-msg error-show" {...field}>
          {meta.error}
        </span>
      ) : null}
    </>
  );
};

export const MySelect = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const { store, dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
    // nursery - grade 6 = 1 - 9
    // grade 7 - 12 = 10

    dispatchStore(
      setGradeIndex(
        document.querySelector("#idSelectStudentGradeLevel").selectedIndex
      )
    );
  }, [meta.error, meta.touched, dispatchStore, store.studentInfo]);

  return (
    <>
      {/* {meta.touched && meta.error && scrollPageUp()} */}
      <label className="label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <select
        {...field}
        {...props}
        className={meta.touched && meta.error ? "valid error-show" : null}
      />
      {meta.touched && meta.error ? (
        <span className="error-msg error-show tr">{meta.error}</span>
      ) : null}
    </>
  );
};

export const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const { dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
  }, [meta.error, meta.touched, dispatchStore]);

  return (
    <>
      <label className="label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea
        {...field}
        {...props}
        className={meta.touched && meta.error ? "valid error-show" : null}
      ></textarea>
      {meta.touched && meta.error ? (
        <span className="error-msg error-show">{meta.error}</span>
      ) : null}
    </>
  );
};

export const MyCheckbox = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const { dispatchStore } = React.useContext(StoreContext);
  // const { email, username, companyId } = store.formError;
  const [field, meta] = useField(props);
  // console.log(props);
  React.useEffect(() => {
    if (meta.touched && meta.error) {
      dispatchStore(loadInfo(true));
      scrollPageUp();
    }
  }, [meta.error, meta.touched, dispatchStore]);

  return (
    <>
      <input
        {...field}
        {...props}
        className={meta.touched && meta.error ? "valid error-show" : null}
      />
      <label htmlFor={props.id || props.name}>{label}</label>
      {/* {meta.touched && meta.error ? (
        <div className="error-msg error-show">{meta.error}</div>
      ) : null} */}
    </>
  );
};
