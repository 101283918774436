import React from "react";

const ButtonNext = ({ props }) => {
  return (
    <button
      type="submit"
      className="btn-green form-control right btn-next"
      id="view-parent"
      // disabled={!props.dirty || !props.isValid || props.isSubmitting}
    >
      NEXT <i className="fas fa-angle-right"> </i>{" "}
      {/* <h4> Student Information </h4> */}
    </button>
  );
};

export default ButtonNext;
