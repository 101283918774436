import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { StoreContext } from "../../store/StoreContext";
import ErrorNotif from "../modal/ErrorNotif";
import { InputText } from "../../module/ModuleFormFields";
import { setStorageRoute, fetchData, baseUrl } from "../../module/functions";
import { loadError, loadMessage } from "../../store/StoreActions";
import Spinner from "../modal/Spinner";

const Login = (props) => {
  const { store, dispatchStore } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    // redirect if already login
    const isAuth = async () => {
      const data = await fetchData(
        baseUrl + "rest/users/read-token.php",
        { token: localStorage.getItem("token") },
        dispatchStore
      );
      console.log(data);
      if (typeof data === "undefined" || !data.status) {
        dispatchStore(loadError(false));
        localStorage.removeItem("token");
        setLoading(false);
        return;
      } else {
        setLoading(false);
        props.history.push("/admin/student");
      }
    };
    isAuth();
  }, [dispatchStore, props.history]);

  const btnStyle = {
    width: "100%",
    display: "block",
    borderRadius: "50px",
  };

  const logoStyle = {
    height: "auto",
    marginBottom: "20px",
  };

  const initVal = {
    email: "",
    password: "",
  };

  const yupSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Required field"),
    password: Yup.string()
      .min(6, "Your password must be at least 6 characters.")
      .required("Required field"),
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container main-top" id="login">
          <div className="content-block no-mg no-pd">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-4 bg-white"
                id="login-input"
              >
                <div className="">
                  <h5 className="t-center">WELCOME TO</h5>
                  <div className="logo t-center">
                    <img
                      src="img/fca-logo.png"
                      alt="FCA Logo"
                      className="mb--10"
                    />
                  </div>
                  <div className="logo t-center" style={logoStyle}>
                    <span>School Management Information System</span>
                  </div>
                  {store.error && <ErrorNotif />}
                  <Formik
                    initialValues={initVal}
                    validationSchema={yupSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      // do ajax
                      const login = await fetchData(
                        baseUrl + "rest/users/read-user-login.php",
                        values,
                        dispatchStore
                      );
                      console.log(login);
                      // When no records found
                      if (!login.status) {
                        console.log(1);
                        dispatchStore(loadError(true));
                        dispatchStore(loadMessage(login.message));
                        return;
                      }

                      // reset form upon successs
                      resetForm();
                      // set local storage
                      setStorageRoute(login.data);
                      // redirect to signup verify page
                      props.history.push(`/admin/student`);
                    }}
                  >
                    {(props) => {
                      // console.log(props.isSubmitting);

                      return (
                        <Form
                          name="nameFormLogin"
                          id="idFormLogin"
                          className=""
                        >
                          <div className="icon-container mb--30 t-center">
                            <InputText
                              type="email"
                              placeholder="Your Registered Email"
                              name="email"
                              disabled={props.isSubmitting}
                            />
                            <i className="fas fa-user"></i>
                          </div>
                          <div className="icon-container mb--30 t-center">
                            <InputText
                              type="password"
                              placeholder="Your Password"
                              name="password"
                              disabled={props.isSubmitting}
                            />
                            <i className="fas fa-lock"></i>
                          </div>
                          <button
                            type="submit"
                            id="idBtnSignIn"
                            className="btn-green form-control mb--40 t-center"
                            style={btnStyle}
                            disabled={
                              !props.dirty ||
                              !props.isValid ||
                              props.isSubmitting
                            }
                          >
                            SIGN IN{" "}
                            {props.isSubmitting && (
                              <i className="fas fa-spinner fa-spin fa-1x"></i>
                            )}
                          </button>
                        </Form>
                      );
                    }}
                  </Formik>
                  <h5 className="t-center">
                    Don't have an account?{" "}
                    <a href="/signup" className="color--lightblue">
                      Sign Up Now
                    </a>
                  </h5>
                  <h5 className="t-center">
                    Did you forget your password?{" "}
                    <a href="/forgot-password" className="color--lightblue">
                      Forgot Password
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
