import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputText } from "../../module/ModuleFormFields";
import { postData } from "../../ajax/RequestMethod";
import { StoreContext } from "../../store/StoreContext";
import { loadError, loadMessage } from "../../store/StoreActions";
import { baseUrl, setStorage } from "../../module/functions";

const SignupForm = ({ btStyle, props }) => {
  const { dispatchStore } = React.useContext(StoreContext);

  const initVal = {
    email: "",
    createPassword: "",
  };

  const yupSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Required field"),
    createPassword: Yup.string()
      .min(6, "Your password must be at least 6 characters.")
      .required("Required field"),
  });

  return (
    <>
      <Formik
        initialValues={initVal}
        validationSchema={yupSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // reset store context
          dispatchStore(loadError(false));

          // do ajax
          const signupData = await postData(
            baseUrl + "rest/users/create-user.php",
            values,
            dispatchStore
          );
          console.log(signupData.data);
          // API Error ocurred
          if (!signupData.data.status) {
            dispatchStore(loadError(true));
            dispatchStore(loadMessage(signupData.data.message));
            return;
          }

          // reset form upon success
          resetForm();

          // redirect to signup verify page
          setStorage();
          props.history.push(`/signup-verification`);
        }}
      >
        {(props) => {
          // console.log(props.isSubmitting);

          return (
            <Form id="idFormSignUp" name="nameFormSignUp">
              <div className="icon-container mb--30 t-center">
                <InputText
                  name="email"
                  type="email"
                  placeholder="Registered Email"
                  disabled={props.isSubmitting}
                />
                <i className="fas fa-envelope"></i>
              </div>

              <div className="icon-container mb--30 t-center">
                <InputText
                  type="password"
                  placeholder="Create Password"
                  name="createPassword"
                  disabled={props.isSubmitting}
                />
                <i className="fas fa-user"></i>
              </div>

              <button
                type="submit"
                href="dashboard.html"
                id="idSignUpABtn"
                name="nameSignUpABtn"
                className="btn-green form-control t-center mb--20"
                style={btStyle}
                disabled={!props.dirty || !props.isValid || props.isSubmitting}
              >
                SIGN UP{" "}
                {props.isSubmitting && (
                  <i className="fas fa-spinner fa-spin fa-1x"></i>
                )}
              </button>
              {!props.isSubmitting && (
                <a
                  href="/login"
                  id="idBackABtn"
                  name="nameBackBtn"
                  className="color--lightblue t-center"
                  style={btStyle}
                >
                  Go back to login
                </a>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignupForm;
